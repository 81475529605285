import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Route, Routes, Link, useLocation, useParams } from "react-router-dom"
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  Button,
  Typography,
  DialogContentText,
  DialogActions,
} from "@mui/material"
import MapIcon from "@mui/icons-material/Map"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import TimerIcon from "@mui/icons-material/Timer"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import CircularProgress from "@mui/material/CircularProgress"
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined"
import Box from "@mui/material/Box"
import ChangesTable from "./components/ChangesTable"
import EditStopsMap from "./components/StopsMap"
import EditShapes from "./components/Shapes"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import GenericEditableTable from "./components/GenericEditableTable"
import axios from "axios"
import config from "../../../config.json"
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn"

const blank_version = {
  gtfs_json: {
    feed_info: {},
    agencies: {},
    fare_rules: {},
    fare_attributes: {},
    frequencies: {},
    calendar: {},
    stops: {},
    routes: {},
    trips: {},
    calendar_dates: {},
    stop_times: {},
    shapes: {},
  },
}

const set_initial_state = (source, pk, set_version, set_version_changes) => {
  if (source === "new") {
    set_version(JSON.parse(JSON.stringify(blank_version)))
  }
  if (source === "version") {
    axios
      .get(config.api_url + `/version/${pk}`)
      .then((response) => {
        set_version(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  if (source === "work_in_progress") {
    axios
      .get(config.api_url + `/work_in_progress/${pk}`)
      .then((response) => {
        set_version_changes(response.data.gtfs_changes)
        set_version(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const drawerWidth = 240

const NavigateBack = ({ feed_pk }) => {
  const [open, set_open] = useState(false)

  const handleClose = () => {
    set_open(false)
  }
  const handleOpen = () => {
    set_open(true)
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography id="form-dialog-title" variant="h5">
            Are you sure?
          </Typography>
          <DialogContentText>
            Are you sure you want to go back to the feed overview? Any unsaved
            work will be lost.
          </DialogContentText>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleClose}
              color="primary"
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              component={Link}
              to={`/feed/${feed_pk}`}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ListItem button onClick={handleOpen}>
        <ListItemIcon>
          <KeyboardReturnIcon />
        </ListItemIcon>
        <ListItemText primary="Back" />
      </ListItem>
    </Fragment>
  )
}

export default function EditGtfs() {
  const [version, set_version] = useState(null)
  const [version_changes, set_version_changes] = useState([])
  const { feed_pk, source, pk } = useParams()

  useEffect(() => {
    set_initial_state(source, pk, set_version, set_version_changes)
  }, [source, pk])

  const location = useLocation()
  const { pathname } = location
  const current_extension = "/" + pathname.split("/").pop()
  const base_path = pathname.split("/").slice(0, -1).join("/")

  const drawer = () => {
    const list_items = [
      {
        key: "feed_info",
        url: "/feed_info",
        text: "Feed Info",
        icon: <InfoOutlinedIcon />,
      },
      {
        key: "agency",
        url: "/agency",
        text: "Agencies",
        icon: <InfoOutlinedIcon />,
      },
      {
        key: "routes",
        url: "/routes",
        text: "Routes",
        icon: <FormatListNumberedIcon />,
      },
      {
        key: "calendar",
        url: "/calendar",
        text: "Calendar",
        icon: <CalendarTodayIcon />,
      },

      {
        key: "calendar_dates",
        url: "/calendar_dates",
        text: "Calendar Dates",
        icon: <CalendarTodayIcon />,
      },
      {
        key: "stops_map",
        url: "/stops_map",
        text: "Stops Map",
        icon: <MapIcon />,
      },
      {
        key: "stops",
        url: "/stops",
        text: "Stops",
        icon: <FormatListNumberedIcon />,
      },
      {
        key: "shapes",
        url: "/shapes",
        text: "Shapes",
        icon: <MapIcon />,
      },
      {
        key: "trips",
        url: "/trips",
        text: "Trips",
        icon: <FormatListNumberedIcon />,
      },
      {
        key: "stop_times",
        url: "/stop_times",
        text: "Stop Times",
        icon: <TimerIcon />,
      },
      {
        key: "frequencies",
        url: "/frequencies",
        text: "Frequencies",
        icon: <TimerIcon />,
      },
      {
        key: "fare_attributes",
        url: "/fare_attributes",
        text: "Fare Attributes",
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        key: "fare_rules",
        url: "/fare_rules",
        text: "Fare Rules",
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        key: "version_changes",
        url: "/version_changes",
        text: `Pending Changes`,
        icon: <FormatListNumberedIcon />,
      },
    ]
    return (
      <div
        style={{
          height: "calc( 100vh - 64px )",
          width: drawerWidth,
          borderRight: "1px lightgrey solid",
          overflow: "auto",
        }}
      >
        <Divider />
        <List style={{ paddingTop: "0px" }}>
          {list_items.map((list_item, index) => {
            return (
              <Fragment key={list_item.key}>
                <ListItem
                  button
                  component={Link}
                  to={base_path + list_item.url}
                  style={
                    current_extension === list_item.url
                      ? {
                          backgroundColor: "Gainsboro",
                          border: "1px solid #555",
                        }
                      : { backgroundColor: null }
                  }
                >
                  <ListItemIcon>{list_item.icon}</ListItemIcon>
                  <ListItemText primary={list_item.text} />
                </ListItem>
                <Divider />
              </Fragment>
            )
          })}
          <NavigateBack feed_pk={feed_pk} />
          <Divider />
        </List>
      </div>
    )
  }

  return (
    <Box sx={{ display: "flex", backgroundColor: "white" }}>
      {useMemo(drawer, [current_extension, base_path, feed_pk])}

      <div
        style={{
          width: `calc( 100vw - ${drawerWidth}px )`,
        }}
      >
        {version === null ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Routes>
            <Route
              path={"feed_info"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="feed_info"
                />
              }
            />
            <Route
              path={"agency"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="agencies"
                />
              }
            />
            <Route
              path={"frequencies"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="frequencies"
                />
              }
            />
            <Route
              path={"stops"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="stops"
                />
              }
            />
            <Route
              path={"routes"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="routes"
                />
              }
            />
            <Route
              path={"calendar"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="calendar"
                />
              }
            />
            <Route
              path={"stop_times"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="stop_times"
                />
              }
            />

            <Route
              path={"trips"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="trips"
                />
              }
            />

            <Route
              path={"calendar_dates"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="calendar_dates"
                />
              }
            />

            <Route
              path={"fare_rules"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="fare_rules"
                />
              }
            />

            <Route
              path={"fare_attributes"}
              element={
                <GenericEditableTable
                  version={version}
                  set_version={set_version}
                  set_version_changes={set_version_changes}
                  table="fare_attributes"
                />
              }
            />

            <Route
              path={"stops_map"}
              element={
                <EditStopsMap
                  version={version}
                  set_version={set_version}
                  version_changes={version_changes}
                  set_version_changes={set_version_changes}
                />
              }
            />
            <Route
              path={"shapes"}
              element={
                <EditShapes
                  version={version}
                  set_version={set_version}
                  version_changes={version_changes}
                  set_version_changes={set_version_changes}
                />
              }
            />
            <Route
              path={"version_changes"}
              element={
                <ChangesTable
                  version_changes={version_changes}
                  set_version_changes={set_version_changes}
                  set_initial_state={set_initial_state}
                  version={version}
                  set_version={set_version}
                  feed_pk={feed_pk}
                  source={source}
                  pk={pk}
                />
              }
            />
          </Routes>
        )}
      </div>
    </Box>
  )
}
