const shapes_to_geo = (shapes) => {
  return shapes
    ? {
        type: "FeatureCollection",
        features: Object.keys(shapes).map((key) => {
          const { coordinates } = shapes[key]
          return {
            type: "Feature",
            properties: { key },
            geometry: {
              type: "LineString",
              coordinates: coordinates.map((coordinate) => [
                parseFloat(coordinate.shape_pt_lon),
                parseFloat(coordinate.shape_pt_lat),
              ]),
            },
          }
        }),
      }
    : null
}

const stops_to_geo = (stops) => {
  return stops
    ? {
        type: "FeatureCollection",
        features: Object.keys(stops).map((key) => {
          const { stop_lon, stop_lat, stop_id, stop_name } = stops[key]
          return {
            id: key,
            type: "Feature",
            properties: { stop_id, stop_name, stop_lon, stop_lat, key },
            geometry: {
              type: "Point",
              coordinates: [parseFloat(stop_lon), parseFloat(stop_lat)],
            },
          }
        }),
      }
    : null
}

const shapes_to_geo_line = (shapes, key) => {
  if (key === null) {
    return {
      type: "FeatureCollection",
      features: [],
    }
  }
  var selected_shape = shapes[key]
  var path_coords = selected_shape.coordinates.map((shape) => [
    parseFloat(shape.shape_pt_lon),
    parseFloat(shape.shape_pt_lat),
  ])
  var from_coords = path_coords.slice(0, path_coords.length - 1)
  var to_coords = path_coords.slice(1, path_coords.length)
  return shapes
    ? {
        type: "FeatureCollection",
        features: to_coords.map((_, index) => {
          return {
            id: index,
            type: "Feature",
            properties: { index: index + 1 },
            geometry: {
              type: "LineString",
              coordinates: [from_coords[index], to_coords[index]],
            },
          }
        }),
      }
    : null
}

const shapes_to_geo_points = (shapes, key) => {
  if (key === null) {
    return {
      type: "FeatureCollection",
      features: [],
    }
  }
  return shapes
    ? {
        type: "FeatureCollection",
        features: shapes[key].coordinates.map((coordinate, index) => {
          return {
            id: index,
            type: "Feature",
            properties: { index, key },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(coordinate.shape_pt_lon),
                parseFloat(coordinate.shape_pt_lat),
              ],
            },
          }
        }),
      }
    : null
}

export { shapes_to_geo, stops_to_geo, shapes_to_geo_points, shapes_to_geo_line }
