import { Bubble } from "../Background/Bubble"
import { Grid, Typography, Button } from "@mui/material"
import Lottie from "react-lottie-player"
import animationData from "./success.json"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import config from "../../config.json"
import axios from "axios"
import setHeaders from "../Feed/EditGtfs/utils/setHeaders"

export default function PaymentSuccess() {
  useEffect(() => {
    axios
      .get(config.api_url + `/login/refresh`)
      .then(({ data }) => {
        setHeaders(data.token)
        localStorage.setItem("token", data.token)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid style={{ marginTop: 50 }} item>
        <Bubble>
          <div style={{ padding: 10 }}>
            <Typography style={{ textAlign: "center" }} variant="h4">
              Payment Successful!
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Lottie
                style={{ width: 300 }}
                loop
                animationData={animationData}
                play
                speed={1}
                rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              />
            </div>
            <Typography variant="h6">
              You have been upgraded to a premium membership.
            </Typography>
            <Typography>
              All functionality on the platform has been unlocked.
            </Typography>
            <Button
              fullWidth
              style={{ marginTop: 20 }}
              variant="outlined"
              to={`/home`}
              component={Link}
            >
              Back to home
            </Button>
          </div>
        </Bubble>
      </Grid>
    </Grid>
  )
}
