const shapes_line_style = {
  id: "shape_lines",
  type: "line",
  paint: {
    "line-color": "DarkBlue",
    "line-width": 6,
  },
  layout: { visibility: "visible" },
}

const info_card = {
  zIndex: 1000,
  position: "absolute",
  top: "10px",
  right: "10px",
  padding: "20px",
  width: "500px",
  overflow: "auto",
  maxHeight: "500px",
}

const shape_point_style = {
  id: "shape_points",
  type: "circle",
  paint: {
    "circle-color": "DarkBlue",
    "circle-opacity": 1,
    "circle-radius": 5,
    "circle-stroke-width": 2,
  },
  layout: { visibility: "visible" },
}

const make_stops_style = (show_stops) => {
  return {
    id: "stops",
    type: "circle",
    paint: {
      "circle-color": "red",
      "circle-stroke-color": "black",
      "circle-opacity": 1,
      "circle-radius": 8,
      "circle-stroke-width": 2,
    },
    layout: { visibility: show_stops ? "visible" : "none" },
  }
}

const make_stops_label_style = (show_stops_labels) => {
  if (!show_stops_labels) {
    return {
      id: "stops_labels",
      type: "symbol",
      display: "none",
      layout: { visibility: "none" },
    }
  }
  return {
    id: "stops_labels",
    type: "symbol",
    layout: {
      "text-field": ["get", "stop_name"],
      "text-rotation-alignment": "auto",
      "text-allow-overlap": false,
      "text-anchor": "bottom",
      "text-radial-offset": 0.8,
      visibility: "visible",
    },
    paint: {
      "text-color": "black",
      "text-halo-color": "white",
      "text-halo-width": 3,
    },
  }
}

const box = {
  margin: "10px",
  border: "1px",
  borderStyle: "solid",
  borderColor: "grey",
  padding: "5px",
  borderRadius: "5px",
}

export {
  shapes_line_style,
  shape_point_style,
  info_card,
  box,
  make_stops_style,
  make_stops_label_style,
}
