import axios from "axios"
import { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"
import config from "../../../config.json"
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

const DeleteFeed = ({ feed }) => {
  const [open, set_open] = useState(false)
  const [is_loading, set_is_loading] = useState(false)
  const [user_feed_name, set_user_feed_name] = useState("")
  const [alert, set_alert] = useState(null)
  const navigate = useNavigate()
  const { feed_pk } = feed

  const handleClose = () => {
    set_open(false)
    set_alert(null)
    set_user_feed_name("")
  }

  const delete_feed = async () => {
    try {
      set_is_loading(true)
      if (feed.name !== user_feed_name) {
        set_is_loading(false)
        return set_alert("Name provided does not match the feed name.")
      }
      await axios.delete(config.api_url + `/feed/${feed_pk}`)
      navigate(`/home`)
    } catch (error) {
      set_is_loading(false)
      console.log(error)
    }
  }
  return (
    <Fragment>
      <IconButton
        variant="contained"
        onClick={() => {
          set_open(true)
        }}
        style={{ float: "right", color: "red" }}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle color="red" id="form-dialog-title">
          Delete Feed (CAUTION)
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="red">
            Are you sure you want to delete this feed? You will not be able to
            recover it and all associated work in progress and versions will be
            deleted.
          </DialogContentText>
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            label="Enter the feed name to continue."
            value={user_feed_name}
            onChange={(e) => {
              set_user_feed_name(e.target.value)
            }}
          />
          {is_loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="outlined" onClick={delete_feed} color="error">
            Delete Feed
          </Button>
        </DialogActions>
        {alert ? <Alert severity="error">{alert}</Alert> : null}
      </Dialog>
    </Fragment>
  )
}

export default DeleteFeed
