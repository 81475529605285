const trip_id_html = (
  <span>
    <p>Identifies a trip.</p>
  </span>
)

const direction_id_html = (
  <span>
    <p>
      Indicates the direction of travel for a trip. This field is not used in
      routing; it provides a way to separate trips by direction when publishing
      time tables.
    </p>
  </span>
)

const trip_headsign_html = (
  <span>
    <p>
      Text that appears on signage identifying the trip's destination to riders.
      Use this field to distinguish between different patterns of service on the
      same route.
    </p>
  </span>
)

const shape_id_html = (
  <span>
    <p>
      Identifies a geospatial shape that describes the vehicle travel path for a
      trip.
    </p>
  </span>
)

const route_id_html = (
  <span>
    <p>Identifies a route.</p>
  </span>
)

const service_id_html = (
  <span>
    <p>
      Identifies a set of dates when service is available for one or more
      routes.
    </p>
  </span>
)

export {
  trip_id_html,
  direction_id_html,
  trip_headsign_html,
  shape_id_html,
  route_id_html,
  service_id_html,
}
