import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import EditIcon from "@mui/icons-material/Edit"
import { Fragment, useEffect, useState } from "react"
import axios from "axios"
import config from "../../../config.json"

const ConfirmDeleteWIP = (props) => {
  const [is_loading, set_is_loading] = useState(false)
  const { delete_wip_pk, set_delete_wip_pk, set_work_in_progresses } = props

  const delete_wip = async () => {
    try {
      const response = await axios.delete(
        config.api_url + `/work_in_progress/${delete_wip_pk}`
      )
      set_work_in_progresses((work_in_progresses) => {
        const updated_work_in_progresses = work_in_progresses.filter(
          (work_in_progress) => {
            return (
              work_in_progress.gtfs_work_in_progress_pk !==
              response.data.gtfs_work_in_progress_pk
            )
          }
        )
        return updated_work_in_progresses
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    set_delete_wip_pk(null)
  }

  return (
    <Dialog
      open={delete_wip_pk !== null}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle color="red" id="form-dialog-title">
        Delete Work in Progress
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="red">
          Are you sure you want to delete this work in progress? You will not be
          able to recover it.
        </DialogContentText>
        {is_loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={async () => {
            set_is_loading(true)
            await delete_wip()
            set_is_loading(false)
            handleClose()
          }}
          color="error"
        >
          Delete Version
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const WorkInProgress = ({ feed_pk }) => {
  const [work_in_progresses, set_work_in_progresses] = useState([])
  const [delete_wip_pk, set_delete_wip_pk] = useState(null)

  useEffect(() => {
    axios
      .get(config.api_url + `/feed/${feed_pk}/work_in_progress`)
      .then(({ data }) => {
        set_work_in_progresses(data)
      })
  }, [feed_pk])

  const user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <Fragment>
      <Typography margin={2} variant="h5">
        Work in Progress
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Created</b>
              </TableCell>
              <TableCell align="center">
                <b>View</b>
              </TableCell>
              <TableCell align="center">
                <b>Edit</b>
              </TableCell>

              <TableCell align="center">
                <b>Delete</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {work_in_progresses.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Alert severity="info">No Work in Progress.</Alert>
                </TableCell>
              </TableRow>
            ) : (
              work_in_progresses.map((work_in_progress) => {
                const { gtfs_work_in_progress_pk, name, log_time } =
                  work_in_progress
                const created_date = new Date(log_time)
                const date = created_date.toLocaleDateString("en-GB", {
                  timeZone: user_timezone,
                })
                const time = created_date.toLocaleTimeString("en-GB", {
                  timeZone: user_timezone,
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                })

                return (
                  <TableRow key={gtfs_work_in_progress_pk}>
                    <TableCell component="th" scope="row" align="center">
                      {name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {date} - {time}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        to={`/view/${feed_pk}/work_in_progress/${gtfs_work_in_progress_pk}/agency`}
                        component={Link}
                      >
                        <VisibilityIcon />
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        to={`/edit/${feed_pk}/work_in_progress/${gtfs_work_in_progress_pk}/agency`}
                        component={Link}
                        onClick={() => {}}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => {
                          set_delete_wip_pk(gtfs_work_in_progress_pk)
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDeleteWIP
        delete_wip_pk={delete_wip_pk}
        set_delete_wip_pk={set_delete_wip_pk}
        set_work_in_progresses={set_work_in_progresses}
      />
    </Fragment>
  )
}

export default WorkInProgress
