const stop_id_html = (
  <span>
    <p>
      Identifies a stop, station, or station entrance. The term "station entrance" refers to both station entrances and
      station exits. Stops, stations or station entrances are collectively referred to as locations. Multiple routes may
      use the same stop.
    </p>
  </span>
)

const stop_name_html = (
  <span>
    <p>Name of the location. Use a name that people will understand in the local and tourist vernacular.</p>
  </span>
)

const stop_latitude_html = (
  <span>
    <p>Latitude of the location.</p>
  </span>
)

const stop_longitude_html = (
  <span>
    <p>Longitude of the location.</p>
  </span>
)

export { stop_name_html, stop_id_html, stop_latitude_html, stop_longitude_html }
