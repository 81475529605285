import React, { Fragment } from "react"
import Typography from "@mui/material/Typography"
import AccountCircle from "@mui/icons-material/AccountCircle"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import { NavLink } from "react-router-dom"
import Toolbar from "@mui/material/Toolbar"
import AppBar from "@mui/material/AppBar"
import { useNavigate } from "react-router-dom"
import { jwtDecode } from "jwt-decode"

const Account = () => {
  const [anchor, setAnchor] = React.useState(null)
  const isMenuOpen = Boolean(anchor)
  const navigate = useNavigate()

  let user = null
  if (localStorage.token) {
    user = jwtDecode(localStorage.token)
  }

  if (!user) {
    return null
  }

  const handleProfileMenuOpen = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchor(null)
  }

  const logout = () => {
    handleMenuClose()
    localStorage.removeItem("token")
    navigate("/login")
  }
  return (
    <Fragment>
      <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <Typography variant="caption">{user.user_email}</Typography>
        </MenuItem>
        <MenuItem onClick={logout}>
          <Typography style={{ float: "right" }} color="red" variant="caption">
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

const NavBar = () => {
  return (
    <AppBar position="fixed" variant="dense">
      <Toolbar>
        <Fragment>
          <NavLink
            to="/home"
            style={{ textDecoration: "none", color: "unset" }}
          >
            <Typography variant="h6">GTFS Platform</Typography>
          </NavLink>
          <Typography style={{ flex: 1 }} align="right">
            <Account />
          </Typography>
        </Fragment>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
