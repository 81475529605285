import { Fragment, useEffect, useState } from "react"
import axios from "axios"
import config from "../../config.json"
import { Avatar, Box, Button, CardHeader, Typography } from "@mui/material"
import { jwtDecode } from "jwt-decode"

const BuyButton = ({ premium }) => {
  const [paymet_data, set_paymet_data] = useState(null)
  useEffect(() => {
    if (!premium) {
      axios.post(config.api_url + "/subscribe").then(({ data }) => {
        set_paymet_data(data)
      })
    }
  }, [premium])

  if (!paymet_data) {
    return null
  }

  return (
    <form action={paymet_data.pfHost} method={paymet_data.method}>
      {Object.keys(paymet_data.details).map((key) => {
        const value = paymet_data.details[key]
        return <input key={key} name={key} type="hidden" value={value.trim()} />
      })}

      <Box display="flex" justifyContent="center" alignItems="center">
        <Button variant="outlined" type="submit">
          Pay Now
        </Button>
      </Box>
    </form>
  )
}

const Subscribe = () => {
  const user = jwtDecode(localStorage.token)
  const { premium } = user

  return (
    <Fragment>
      <CardHeader
        avatar={
          <Avatar
            variant="square"
            src={`${premium ? "/images/crown.png" : "/images/lock.png"}`}
          />
        }
        title={"Premium Membership"}
        titleTypographyProps={{ variant: "h5" }}
      />

      {premium ? (
        <Fragment>
          <Typography margin={2}>
            You are a premium user of the platform. All functionality has been
            unlocked.
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Typography margin={2}>
            Are you ready to subscribe? Click the button below!
          </Typography>
          <BuyButton premium={premium} />
        </Fragment>
      )}
    </Fragment>
  )
}

export default Subscribe
