import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import CreateIcon from "@mui/icons-material/Create"
import DrawIcon from "@mui/icons-material/Draw"
import WorkHistoryIcon from "@mui/icons-material/WorkHistory"
import PendingIcon from "@mui/icons-material/Pending"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import TableViewIcon from "@mui/icons-material/TableView"
const items = [
  {
    icon: <DrawIcon />,
    title: "Create feeds",
    description:
      "Upload entire feeds on create them from scratch through a step-by-step process.",
  },
  {
    icon: <CreateIcon />,
    title: "Manage feeds",
    description:
      "Edit, download and delete feeds. Downloaded feeds can be uploaded to Google.",
  },
  {
    icon: <WorkHistoryIcon />,
    title: "Control versions",
    description:
      "Save edits as new versions and have a history of changes to refer to.",
  },
  {
    icon: <PendingIcon />,
    title: "Save work in progress",
    description:
      "Don't redo work. Save work in progress and continue at a later stage.",
  },
  {
    icon: <FactCheckIcon />,
    title: "Validate feeds",
    description: "Use the built-in validator to validate entire feeds.",
  },
  {
    icon: <TableViewIcon />,
    title: "Create separate feeds for multiple transport operators",
    description:
      "Multiple transport operators' feeds can be added and separated.",
  },
]

export default function Features() {
  return (
    <Box
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        // backgroundImage:
        //   "linear-gradient(140deg, #000 0%, #040404 40%, #65158d 80%, #bc5060 100%)",
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" color={"black"}>
            Product Features
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            What makes our product great? Explore our product features.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "primary.main",
                }}
              >
                <Box sx={{ opacity: "80%" }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
