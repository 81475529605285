import { DataGrid } from "@mui/x-data-grid"

const GenericTable = (props) => {
  let { data, columns, title } = props
  const rows = Object.keys(data).map((key) => {
    let row = data[key]
    return row
  })

  Object.keys(data).forEach((key, index) => {
    data[key].id = index
  })

  columns = columns.map((column) => {
    column.flex = 1
    column.minWidth = 100
    return column
  })

  return (
    <div style={{ height: "calc( 100vh - 64px )", width: "100%" }}>
      <DataGrid
        style={{ border: "none" }}
        title={title}
        minWidth
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 15, 20, 25]}
      />
    </div>
  )
}

export default GenericTable
