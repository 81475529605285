import axios from "axios"
import { Fragment, useEffect, useState } from "react"
import config from "../../../config.json"
import { Typography, Grid } from "@mui/material"
import DeleteFeed from "./DeleteFeed"
import EditFeedInfo from "./EditFeedInfo"
import { useNavigate } from "react-router-dom"

const GridItem = (props) => {
  const { title, amount } = props
  return (
    <Grid item align="center" md={1.5}>
      <Typography variant="h6">
        {title} <br /> {amount}
      </Typography>
    </Grid>
  )
}

const Header = ({ feed_pk, num_versions }) => {
  const [feed, set_feed] = useState(null)
  const [stats, set_stats] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(config.api_url + `/feed/${feed_pk}/stats`)
      .then(({ data }) => {
        set_stats(data)
      })
      .catch((error) => {
        set_stats(null)
      })
  }, [feed_pk, num_versions])

  useEffect(() => {
    axios
      .get(config.api_url + `/feed/${feed_pk}`)
      .then(({ data }) => {
        set_feed(data)
      })
      .catch((error) => {
        console.log(error)
        navigate("/home")
      })
  }, [feed_pk, navigate])

  return (
    <Fragment>
      <div style={{ width: "100%", height: 32 }}>
        {feed ? <DeleteFeed feed={feed} /> : null}
        {feed ? <EditFeedInfo feed={feed} set_feed={set_feed} /> : null}
      </div>

      {feed ? (
        <Fragment>
          <Typography padding={1} textAlign="center" variant="h3">
            {feed.name}
          </Typography>
          <Typography padding={1} textAlign="center" variant="h4">
            {feed.description}
          </Typography>{" "}
        </Fragment>
      ) : null}

      {stats ? (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <GridItem title={"Routes"} amount={stats.routes} />
          <GridItem title={"Calendars"} amount={stats.calendar} />
          <GridItem title={"Calendar Dates"} amount={stats.calendar_dates} />
          <GridItem title={"Stops"} amount={stats.stops} />
          <GridItem title={"Shapes"} amount={stats.shapes} />
          <GridItem title={"Trips"} amount={stats.trips} />
          <GridItem title={"Stop Times"} amount={stats.stop_times} />
          <GridItem title={"Frequencies"} amount={stats.frequencies} />
        </Grid>
      ) : null}
    </Fragment>
  )
}

export default Header
