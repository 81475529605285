import { useState, Fragment } from "react"
import TextField from "@mui/material/TextField"
import SearchIcon from "@mui/icons-material/Search"
import InputAdornment from "@mui/material/InputAdornment"
import SettingsIcon from "@mui/icons-material/Settings"
import Menu from "@mui/material/Menu"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"

const find_matching_rows = (gtfs_json, search_settings) => {
  let rows = []

  let tables = [
    "trips",
    "stops",
    "stop_times",
    "routes",
    "frequencies",
    "fare_rules",
    "fare_attributes",
    "calendar",
    "calendar_dates",
  ]

  table_loop: for (let i = 0; i < tables.length; i++) {
    const search_term = search_settings.name
    const search_term_length = search_term.length
    const table_name = tables[i]
    if (search_settings[table_name]) {
      for (let [, entry] of Object.entries(gtfs_json[table_name])) {
        for (let [attribute_key, attribute_value] of Object.entries(entry)) {
          attribute_value = attribute_value.toString()
          const string_index = attribute_value
            .toUpperCase()
            .indexOf(search_term.toUpperCase())
          if (string_index !== -1) {
            if (rows.length < 30) {
              rows.push([
                table_name,
                entry,
                {
                  attribute_key,
                  parts: {
                    prefix: attribute_value.slice(0, string_index),
                    phrase: attribute_value.slice(
                      string_index,
                      string_index + search_term_length
                    ),
                    suffix: attribute_value.slice(
                      string_index + search_term_length
                    ),
                  },
                },
              ])
            } else {
              break table_loop
            }
          }
        }
      }
    }
  }

  return rows
}

const initial_search_settings = {
  trips: true,
  stops: true,
  stop_times: true,
  routes: true,
  frequencies: true,
  fare_rules: true,
  fare_attributes: true,
  calendar: true,
  calendar_dates: true,
  name: "",
}

const SearchSettings = (props) => {
  const { search_settings, set_search_settings } = props
  const [anchorElSetting, setAnchorElSetting] = useState(null)

  const handleCloseSettingMenu = () => {
    setAnchorElSetting(null)
  }

  const handleOpenSettingMenu = (event) => {
    setAnchorElSetting(event.currentTarget)
  }

  const handle_change = (event) => {
    const { checked, name, value } = event.target

    set_search_settings((search_settings) => {
      const new_search_settings = { ...search_settings }
      new_search_settings[name] = name === "name" ? value : checked
      return new_search_settings
    })
  }

  return (
    <Fragment>
      <TextField
        style={{ marginTop: 5 }}
        fullWidth
        onChange={handle_change}
        name="name"
        value={search_settings.name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              style={{ cursor: "pointer" }}
              position="end"
              onClick={handleOpenSettingMenu}
            >
              <SettingsIcon />
            </InputAdornment>
          ),
        }}
      />
      <Menu
        disableAutoFocus
        sx={{ mt: "45px", margin: "10px" }}
        id="menu-appbar"
        anchorEl={anchorElSetting}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElSetting)}
        onClose={handleCloseSettingMenu}
      >
        <div style={{ width: "400px", padding: "10px" }}>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Select Search Tables</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.routes}
                    onChange={handle_change}
                    name="routes"
                  />
                }
                label="Routes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.calendar}
                    onChange={handle_change}
                    name="calendar"
                  />
                }
                label="Calendar"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.calendar_dates}
                    onChange={handle_change}
                    name="calendar_dates"
                  />
                }
                label="Calendar Dates"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.stops}
                    onChange={handle_change}
                    name="stops"
                  />
                }
                label="Stops"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.trips}
                    onChange={handle_change}
                    name="trips"
                  />
                }
                label="Trips"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.stop_times}
                    onChange={handle_change}
                    name="stop_times"
                  />
                }
                label="Stop Times"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.frequencies}
                    onChange={handle_change}
                    name="frequencies"
                  />
                }
                label="Frequencies"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.fare_attributes}
                    onChange={handle_change}
                    name="fare_attributes"
                  />
                }
                label="Fare Attributes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={search_settings.fare_rules}
                    onChange={handle_change}
                    name="fare_rules"
                  />
                }
                label="Fare Rules"
              />
            </FormGroup>
            <FormHelperText>
              Selected fields will show up in search results.
            </FormHelperText>
          </FormControl>
        </div>
      </Menu>
    </Fragment>
  )
}

const Search = (props) => {
  const [search_settings, set_search_settings] = useState(
    initial_search_settings
  )

  const { gtfs_json } = props

  let rows = []
  if (search_settings.name) {
    rows = find_matching_rows(gtfs_json, search_settings)
  }

  return (
    <Fragment>
      <SearchSettings
        search_settings={search_settings}
        set_search_settings={set_search_settings}
      />
      <TableContainer style={{ maxHeight: "calc(100vh - 125px)" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Table</TableCell>
              <TableCell align="center">values</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const [table_name, entry, match] = row
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {table_name}
                  </TableCell>
                  <TableCell align="center">
                    {Object.keys(entry).map((key, i) => {
                      if (key.includes("key")) {
                        return null
                      } else if (key === match.attribute_key) {
                        const { prefix, phrase, suffix } = match.parts
                        return (
                          <Fragment key={key}>
                            {key.replace("_", " ")}:{prefix}
                            <b style={{ backgroundColor: "lightgrey" }}>
                              {phrase}
                            </b>
                            {suffix}
                            {i !== Object.keys(entry).length - 1 ? ", " : null}
                          </Fragment>
                        )
                      } else {
                        return (
                          <Fragment key={key}>
                            {key.replace("_", " ")}:{entry[key]}
                            {i !== Object.keys(entry).length - 1 ? ", " : null}
                          </Fragment>
                        )
                      }
                    })}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  )
}

export default Search
