const fare_id_html = (
  <span>
    <p>Identifies a fare class.</p>
  </span>
)

const price_html = (
  <span>
    <p>Fare price, in the unit specified by Currency Type.</p>
  </span>
)

const currency_type_html = (
  <span>
    <p>Currency used to pay the fare.</p>
  </span>
)

const payment_method_html = (
  <span>
    <p>Indicates when the fare must be paid. Valid options are:</p>
    <p>On board - Fare is paid on board.</p>
    <p>Before boarding - Fare must be paid before boarding.</p>
  </span>
)

const transfers_html = (
  <span>
    <p>
      Indicates the number of transfers permitted on this fare. The fact that
      this field can be left empty is an exception to the requirement that a
      Required field must not be empty. Valid options are:
    </p>
    <p>0 - No transfers permitted on this fare.</p>
    <p>1 - Riders may transfer once.</p>
    <p>
      2 - Riders may transfer twice. empty - Unlimited transfers are permitted.
    </p>
  </span>
)

const transfer_duration_html = (
  <span>
    <p>
      Length of time in seconds before a transfer expires. When transfers=0 this
      field can be used to indicate how long a ticket is valid for or it can can
      be left empty.
    </p>
  </span>
)

export {
  fare_id_html,
  price_html,
  currency_type_html,
  payment_method_html,
  transfers_html,
  transfer_duration_html,
}
