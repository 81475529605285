import {
  feed_publisher_name_html,
  feed_publisher_url_html,
  feed_lang_html,
  default_lang_html,
  feed_start_date_html,
  feed_end_date_html,
  feed_version_html,
  feed_contact_email_html,
  feed_contact_url_html,
} from "../Tooltips/feed_info"
import {
  agency_id_html,
  agency_name_html,
  agency_url_html,
  agency_timezone_html,
  agency_lang_html,
  agency_phone_html,
} from "../Tooltips/agency"
import {
  start_time_html,
  end_time_html,
  headway_secs_html,
  exact_times_html,
  trip_id_html,
} from "../Tooltips/frequencies"
import {
  stop_name_html,
  stop_id_html,
  stop_latitude_html,
  stop_longitude_html,
} from "../Tooltips/stops"
import {
  route_id_html,
  route_type_html,
  route_short_name_html,
  route_long_name_html,
} from "../Tooltips/routes"
import {
  service_id_html,
  monday_html,
  tuesday_html,
  wednesday_html,
  thursday_html,
  friday_html,
  saturday_html,
  sunday_html,
  start_date_html,
  end_date_html,
} from "../Tooltips/calendar"
import {
  stop_sequence_html,
  timepoint_html,
  stop_headsign_html,
  arrival_time_html,
  departure_time_html,
  trip_id_html as stop_times_trip_id_html,
  stop_id_html as stop_times_stop_id_html,
} from "../Tooltips/stop_times"
import {
  trip_id_html as trips_trip_id_html,
  direction_id_html,
  trip_headsign_html,
  shape_id_html,
  route_id_html as trips_route_id_html,
  service_id_html as trips_service_id_html,
} from "../Tooltips/trips"
import {
  date_html,
  exception_type_html,
  service_id_html as calendar_dates_service_id_html,
} from "../Tooltips/calendar_dates"
import {
  origin_id_html,
  destination_id_html,
  constains_id_html,
  fare_id_html,
  route_id_html as fare_rules_route_id_html,
} from "../Tooltips/fare_rules"
import {
  fare_id_html as fare_attributes_fare_id_html,
  price_html,
  currency_type_html,
  payment_method_html,
  transfers_html,
  transfer_duration_html,
} from "../Tooltips/fare_attributes"
import {
  language_options,
  timezone_options,
  service_options,
  service_exception_options,
  trip_direction_options,
  route_types_options,
  timepoint_options,
  exact_time_options,
  payment_method_options,
} from "../constants/constants"
import max_date from "../utils/maxDate"

const field_specification = {
  feed_info: [
    {
      label: "Publisher Name",
      field: "feed_publisher_name",
      type: "text",
      tooltip: feed_publisher_name_html,
      required: true,
    },
    {
      label: "Publisher URL",
      field: "feed_publisher_url",
      type: "text",
      tooltip: feed_publisher_url_html,
      required: true,
    },
    {
      label: "Language",
      field: "feed_lang",
      type: "select",
      tooltip: feed_lang_html,
      required: true,
      options: language_options,
    },
    {
      label: "Default Language",
      field: "default_lang",
      type: "select",
      tooltip: default_lang_html,
      required: false,
      options: language_options,
    },
    {
      label: "Start Date",
      field: "feed_start_date",
      type: "date",
      tooltip: feed_start_date_html,
      required: false,
      inputProps: {
        max: max_date(),
      },
    },
    {
      label: "End Date",
      field: "feed_end_date",
      type: "date",
      tooltip: feed_end_date_html,
      required: false,
      inputProps: {
        max: max_date(),
      },
    },
    {
      label: "Version",
      field: "feed_version",
      type: "number",
      tooltip: feed_version_html,
      required: false,
    },
    {
      label: "Contact Email",
      field: "feed_contact_email",
      type: "text",
      tooltip: feed_contact_email_html,
      required: false,
    },
    {
      label: "Contact URL",
      field: "feed_contact_url",
      type: "text",
      tooltip: feed_contact_url_html,
      required: false,
    },
  ],
  agencies: [
    {
      label: "Agency Id",
      field: "agency_id",
      type: "text",
      tooltip: agency_id_html,
      required: true,
    },
    {
      label: "Agency Name",
      field: "agency_name",
      type: "text",
      tooltip: agency_name_html,
      required: true,
    },
    {
      label: "Agency Phone",
      field: "agency_phone",
      type: "text",
      tooltip: agency_phone_html,
      required: false,
      inputProps: { maxLength: 10 },
    },
    {
      label: "Agency Url",
      field: "agency_url",
      type: "text",
      tooltip: agency_url_html,
      required: true,
    },
    {
      label: "Agency Language",
      field: "agency_lang",
      type: "select",
      tooltip: agency_lang_html,
      required: false,
      options: language_options,
    },
    {
      label: "Agency Timezone",
      field: "agency_timezone",
      type: "select",
      tooltip: agency_timezone_html,
      required: true,
      options: timezone_options,
    },
  ],
  frequencies: [
    {
      label: "Trip Id",
      field: "trip_key",
      type: "reference",
      reference_table: "trips",
      tooltip: trip_id_html,
      required: true,
    },
    {
      label: "Start Time",
      field: "start_time",
      type: "time",
      tooltip: start_time_html,
      required: true,
      inputProps: { step: "1" },
    },
    {
      label: "End Time",
      field: "end_time",
      type: "time",
      tooltip: end_time_html,
      required: true,
      inputProps: { step: "1" },
    },
    {
      label: "Headway Secs",
      field: "headway_secs",
      type: "number",
      tooltip: headway_secs_html,
      required: true,
      inputProps: { min: "0", step: "1" },
    },
    {
      label: "Exact Times",
      field: "exact_times",
      type: "select",
      tooltip: exact_times_html,
      options: exact_time_options,
      required: false,
    },
  ],
  stops: [
    {
      label: "Stop Name",
      field: "stop_name",
      type: "text",
      tooltip: stop_name_html,
      required: true,
    },
    {
      label: "Stop Id",
      field: "stop_id",
      type: "text",
      tooltip: stop_id_html,
      required: true,
    },
    {
      label: "Stop Latitude",
      field: "stop_lat",
      type: "number",
      tooltip: stop_latitude_html,
      required: true,
    },
    {
      label: "Stop Longitude",
      field: "stop_lon",
      type: "number",
      tooltip: stop_longitude_html,
      required: true,
    },
  ],
  routes: [
    {
      label: "Route Id",
      field: "route_id",
      type: "text",
      tooltip: route_id_html,
      required: true,
    },
    {
      label: "Agency Id",
      field: "agency_key",
      type: "reference",
      reference_table: "agencies",
      tooltip: agency_id_html,
      required: true,
    },
    {
      label: "Route Type",
      field: "route_type",
      type: "select",
      options: route_types_options,
      tooltip: route_type_html,
      required: true,
    },
    {
      label: "Route Short Name",
      field: "route_short_name",
      type: "text",
      tooltip: route_short_name_html,
      required: true,
    },
    {
      label: "Route Long Name",
      field: "route_long_name",
      type: "text",
      tooltip: route_long_name_html,
      required: true,
    },
  ],
  calendar: [
    {
      label: "Service Id",
      field: "service_id",
      type: "text",
      tooltip: service_id_html,
      required: true,
    },
    {
      label: "Start Date",
      field: "start_date",
      type: "date",
      tooltip: start_date_html,
      required: true,
      inputProps: {
        max: max_date(),
      },
    },
    {
      label: "End Date",
      field: "end_date",
      type: "date",
      tooltip: end_date_html,
      required: true,
      inputProps: {
        max: max_date(),
      },
    },
    {
      label: "Monday",
      field: "monday",
      type: "select",
      tooltip: monday_html,
      required: true,
      options: service_options,
    },
    {
      label: "Tuesday",
      field: "tuesday",
      type: "select",
      tooltip: tuesday_html,
      required: true,
      options: service_options,
    },
    {
      label: "Wednesday",
      field: "wednesday",
      type: "select",
      tooltip: wednesday_html,
      required: true,
      options: service_options,
    },
    {
      label: "Thursday",
      field: "thursday",
      type: "select",
      tooltip: thursday_html,
      required: true,
      options: service_options,
    },
    {
      label: "Friday",
      field: "friday",
      type: "select",
      tooltip: friday_html,
      required: true,
      options: service_options,
    },
    {
      label: "Saturday",
      field: "saturday",
      type: "select",
      tooltip: saturday_html,
      required: true,
      options: service_options,
    },
    {
      label: "Sunday",
      field: "sunday",
      type: "select",
      tooltip: sunday_html,
      required: true,
      options: service_options,
    },
  ],
  stop_times: [
    {
      label: "Trip Id",
      field: "trip_key",
      type: "reference",
      reference_table: "trips",
      required: true,
      tooltip: stop_times_trip_id_html,
    },
    {
      label: "Stop Id",
      field: "stop_key",
      type: "reference",
      reference_table: "stops",
      required: true,
      tooltip: stop_times_stop_id_html,
    },
    {
      label: "Stop Sequence",
      field: "stop_sequence",
      type: "number",
      required: true,
      tooltip: stop_sequence_html,
      inputProps: { min: "0", step: "1" },
    },
    {
      label: "Arrival Time",
      field: "arrival_time",
      type: "time",
      required: true,
      tooltip: arrival_time_html,
      inputProps: { step: "1" },
    },
    {
      label: "Departure Time",
      field: "departure_time",
      type: "time",
      required: true,
      tooltip: departure_time_html,
      inputProps: { step: "1" },
    },
    {
      label: "Timepoint",
      field: "timepoint",
      type: "select",
      required: true,
      options: timepoint_options,
      tooltip: timepoint_html,
    },
    {
      label: "Stop Headsign",
      field: "stop_headsign",
      type: "text",
      required: false,
      tooltip: stop_headsign_html,
    },
  ],
  trips: [
    {
      label: "Trip Id",
      field: "trip_id",
      type: "text",
      required: true,
      tooltip: trips_trip_id_html,
    },
    {
      label: "Direction Id",
      field: "direction_id",
      type: "select",
      options: trip_direction_options,
      required: false,
      tooltip: direction_id_html,
    },
    {
      label: "Trip Headsign",
      field: "trip_headsign",
      type: "text",
      required: false,
      tooltip: trip_headsign_html,
    },
    {
      label: "Shape Id",
      field: "shape_key",
      reference_table: "shapes",
      type: "reference",
      required: true,
      tooltip: shape_id_html,
    },
    {
      label: "Route Id",
      field: "route_key",
      reference_table: "routes",
      type: "reference",
      required: true,
      tooltip: trips_route_id_html,
    },
    {
      label: "Service Id",
      field: "calendar_key",
      reference_table: "calendar",
      type: "reference",
      required: true,
      tooltip: trips_service_id_html,
    },
  ],
  calendar_dates: [
    {
      label: "Service Id",
      field: "calendar_key",
      reference_table: "calendar",
      type: "reference",
      required: true,
      tooltip: calendar_dates_service_id_html,
    },
    {
      label: "Date",
      field: "date",
      type: "date",
      required: true,
      tooltip: date_html,
      inputProps: {
        max: max_date(),
      },
    },
    {
      label: "Exception Type",
      field: "exception_type",
      type: "select",
      required: true,
      tooltip: exception_type_html,
      options: service_exception_options,
    },
  ],
  fare_rules: [
    {
      label: "Origin Id",
      field: "origin_id",
      type: "text",
      required: true,
      tooltip: origin_id_html,
    },
    {
      label: "Destination Id",
      field: "destination_id",
      type: "text",
      required: false,
      tooltip: destination_id_html,
    },
    {
      label: "Contains Id",
      field: "constains_id",
      type: "text",
      required: false,
      tooltip: constains_id_html,
    },
    {
      label: "Fare Id",
      field: "fare_pk",
      type: "reference",
      reference_table: "fare_attributes",
      required: false,
      tooltip: fare_id_html,
    },
    {
      label: "Route Id",
      field: "route_pk",
      type: "reference",
      reference_table: "routes",
      required: false,
      tooltip: fare_rules_route_id_html,
    },
  ],
  fare_attributes: [
    {
      label: "Fare Id",
      field: "fare_id",
      type: "text",
      required: true,
      tooltip: fare_attributes_fare_id_html,
    },
    {
      label: "Price",
      field: "price",
      type: "number",
      required: true,
      tooltip: price_html,
      inputProps: { min: "0" },
    },
    {
      label: "Currency Type",
      field: "currency_type",
      type: "text",
      required: true,
      tooltip: currency_type_html,
    },
    {
      label: "Payment Method",
      field: "payment_method",
      type: "select",
      required: true,
      options: payment_method_options,
      tooltip: payment_method_html,
    },
    {
      label: "Transfers",
      field: "transfers",
      type: "number",
      required: true,
      tooltip: transfers_html,
      inputProps: { min: "0", max: "100", step: "1" },
    },
    {
      label: "Transfer Duration",
      field: "transfer_duration",
      type: "number",
      required: false,
      tooltip: transfer_duration_html,
      inputProps: { min: "0", step: "1" },
    },
  ],
}

export default field_specification
