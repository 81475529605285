import axios from "axios"
import { Fragment, useState } from "react"
import config from "../../../config.json"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"

const EditFeedInfo = ({ feed, set_feed }) => {
  const [open, set_open] = useState(false)
  const [is_loading, set_is_loading] = useState(false)
  const [form_data, set_form_data] = useState({ name: "", description: "" })

  const handleClose = () => {
    set_open(false)
  }

  const update_feed_info = async () => {
    try {
      set_is_loading(true)
      const { data } = await axios.put(config.api_url + `/feed/`, form_data)
      set_feed(data)
      handleClose()
    } catch (error) {
      console.log(error)
    } finally {
      set_is_loading(false)
    }
  }

  return (
    <Fragment>
      <IconButton
        variant="contained"
        onClick={() => {
          set_form_data({ ...feed })
          set_open(true)
        }}
        style={{ float: "right" }}
      >
        <EditOutlinedIcon color="lightgray" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update Feed Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the name and description of the feed.
          </DialogContentText>
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            label="Name"
            value={form_data.name}
            onChange={(e) => {
              set_form_data((data) => {
                let new_data = { ...data }
                new_data.name = e.target.value
                return new_data
              })
            }}
          />
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            label="Description"
            value={form_data.description}
            onChange={(e) => {
              set_form_data((data) => {
                let new_data = { ...data }
                new_data.description = e.target.value
                return new_data
              })
            }}
          />

          {is_loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="outlined" onClick={update_feed_info}>
            Update Feed Information
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default EditFeedInfo
