const stop_sequence_html = (
  <span>
    <p>
      Order of stops for a particular trip. The values must increase along the
      trip but do not need to be consecutive.
    </p>
  </span>
)

const timepoint_html = (
  <span>
    <p>
      Indicates if arrival and departure times for a stop are strictly adhered
      to by the vehicle or if they are instead approximate and/or interpolated
      times. This field allows a GTFS producer to provide interpolated
      stop-times, while indicating that the times are approximate. Valid options
      are:
    </p>
    <p>Approximate - Times are considered approximate.</p>
    <p>Exact - Times are considered exact.</p>
  </span>
)

const stop_headsign_html = (
  <span>
    <p>
      Text that appears on signage identifying the trip's destination to riders.
    </p>
  </span>
)

const arrival_time_html = (
  <span>
    <p>
      Arrival time at a specific stop for a specific trip on a route. If there
      are not separate times for arrival and departure at a stop, enter the same
      value for arrival_time and departure_time. For times occurring after
      midnight on the service day, enter the time as a value greater than
      24:00:00 in HH:MM:SS local time for the day on which the trip schedule
      begins.
    </p>
    <p>
      Scheduled stops where the vehicle strictly adheres to the specified
      arrival and departure times are timepoints. If this stop is not a
      timepoint, it is recommended to provide an estimated or interpolated time.
      If this is not available, arrival_time can be left empty. Further,
      indicate that interpolated times are provided with timepoint=0. If
      interpolated times are indicated with timepoint=0, then time points must
      be indicated with timepoint=1. Provide arrival times for all stops that
      are time points. An arrival time must be specified for the first and the
      last stop in a trip.
    </p>
  </span>
)

const departure_time_html = (
  <span>
    <p>
      Departure time from a specific stop for a specific trip on a route. For
      times occurring after midnight on the service day, enter the time as a
      value greater than 24:00:00 in HH:MM:SS local time for the day on which
      the trip schedule begins. If there are not separate times for arrival and
      departure at a stop, enter the same value for arrival_time and
      departure_time. See the arrival_time description for more details about
      using timepoints correctly.
    </p>
    <p>
      The departure_time field should specify time values whenever possible,
      including non-binding estimated or interpolated times between timepoints.
    </p>
  </span>
)

const trip_id_html = (
  <span>
    <p>Identifies a trip.</p>
  </span>
)

const stop_id_html = (
  <span>
    <p>Identifies the serviced stop.</p>
  </span>
)

export {
  stop_sequence_html,
  timepoint_html,
  stop_headsign_html,
  arrival_time_html,
  departure_time_html,
  trip_id_html,
  stop_id_html,
}
