import Map, { Source, Layer } from "react-map-gl"
import config from "../../../config.json"
import { determine_initial_view_state } from "../Utils/map"
import { shapes_line_style, info_card, make_stops_style } from "../Utils/styles"
import { shapes_to_geo, stops_to_geo } from "../Utils/conversion"
import { Fragment, useState } from "react"
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import "mapbox-gl/dist/mapbox-gl.css"

const StopInfoCard = ({ stop_key, set_stop_key, stops }) => {
  if (!stop_key) {
    return null
  }
  const { stop_id, stop_name, stop_lon, stop_lat } = stops[stop_key]

  return (
    <Card style={info_card}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5">Stop Information</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            style={{ float: "right" }}
            onClick={() => {
              set_stop_key(null)
            }}
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Stop Name:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{stop_name}</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Latitude:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{stop_lat}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Typography>Longitude:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{stop_lon}</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Stop Id:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{stop_id}</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

const ShapeInfoCard = ({
  shape_key,
  set_shape_key,
  trips,
  shapes,
  stop_times,
}) => {
  if (!shape_key) {
    return null
  }

  const { shape_id } = shapes[shape_key]
  let trips_using_shape = []
  Object.keys(trips).forEach((trip_key) => {
    const trip = trips[trip_key]
    if (parseInt(trip.shape_key) === parseInt(shape_key)) {
      let stop_times_for_trip = []
      Object.keys(stop_times).forEach((stop_time_key) => {
        const stop_time = stop_times[stop_time_key]
        if (parseInt(trip_key) === parseInt(stop_time.trip_key)) {
          stop_times_for_trip.push(stop_time)
        }
      })
      stop_times_for_trip.sort(function (a, b) {
        return parseInt(a.stop_sequence) < parseInt(b.stop_sequence)
      })

      trips_using_shape.push({
        trip_id: trip.trip_id,
        origin: stop_times_for_trip[0],
        destination: stop_times_for_trip[stop_times_for_trip.length - 1],
      })
    }
  })

  return (
    <Card style={info_card}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h5">Trip Information</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            style={{ float: "right" }}
            onClick={() => {
              set_shape_key(null)
            }}
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <Typography style={{ padding: "20px" }}>
        Shape Id: <b>{shape_id}</b>{" "}
      </Typography>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Trip Id</TableCell>
            <TableCell align="center">Origin Stop Id</TableCell>
            <TableCell align="center">Destination Stop Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trips_using_shape.map((trip) => (
            <TableRow key={trip.trip_id}>
              <TableCell align="center">{trip.trip_id}</TableCell>
              <TableCell align="center">{trip.origin.stop_id}</TableCell>
              <TableCell align="center">{trip.destination.stop_id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

const handle_map_click = (click, set_stop_key, set_shape_key) => {
  const { features } = click
  if (features.length === 0) {
    return
  }
  const { source, properties } = features[0]
  if (source === "stops_source") {
    set_shape_key(null)
    set_stop_key(properties.key)
  } else if (source === "shapes_source") {
    set_shape_key(properties.key)
    set_stop_key(null)
  }
}

const MapView = ({ stops, shapes, trips, stop_times }) => {
  const [stop_key, set_stop_key] = useState(null)
  const [shape_key, set_shape_key] = useState(null)

  return (
    <Fragment>
      <Map
        style={{ width: "100%", height: "calc(100vh - 64px)" }}
        mapboxAccessToken={config.mapbox_token}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        initialViewState={determine_initial_view_state(stops)}
        interactiveLayerIds={["shape_lines", "stops"]}
        onClick={(click) =>
          handle_map_click(click, set_stop_key, set_shape_key)
        }
      >
        <Source type="geojson" data={shapes_to_geo(shapes)} id="shapes_source">
          <Layer {...shapes_line_style} />
        </Source>
        <Source type="geojson" data={stops_to_geo(stops)} id="stops_source">
          <Layer {...make_stops_style(true)} />
        </Source>
      </Map>
      <StopInfoCard
        stop_key={stop_key}
        set_stop_key={set_stop_key}
        stops={stops}
      />
      <ShapeInfoCard
        shape_key={shape_key}
        set_shape_key={set_shape_key}
        trips={trips}
        shapes={shapes}
        stop_times={stop_times}
      />
    </Fragment>
  )
}

export default MapView
