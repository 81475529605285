const format_date = (date) => {
  if (date === undefined) {
    return ''
  }
  const year = date.substring(0, 4)
  const month = date.substring(4, 6)
  const day = date.substring(6, 9)
  const formatted_date = year + '-' + month + '-' + day
  return formatted_date
}

export default format_date
