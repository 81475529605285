const shape_key_used_in_version = (gtfs_json, shape_key) => {
  let shape_key_used_in_version = false
  // Check if shape_key used in trips
  Object.keys(gtfs_json.trips).forEach((trip_key) => {
    const trip = gtfs_json.trips[trip_key]
    if (trip.shape_key.toString() === shape_key.toString()) {
      shape_key_used_in_version = true
    }
  })
  return shape_key_used_in_version
}

const trip_key_used_in_version = (gtfs_json, trip_key) => {
  let trip_key_used_in_version = false
  // Check if trip_key used in frequencies
  Object.keys(gtfs_json.frequencies).forEach((frequency_key) => {
    const freqeuncy = gtfs_json.frequencies[frequency_key]
    if (freqeuncy.trip_key.toString() === trip_key.toString()) {
      trip_key_used_in_version = true
    }
  })
  // Check if trip_key used in stop_times
  Object.keys(gtfs_json.stop_times).forEach((stop_time_key) => {
    const stop_time = gtfs_json.stop_times[stop_time_key]
    if (stop_time.trip_key.toString() === trip_key.toString()) {
      trip_key_used_in_version = true
    }
  })
  return trip_key_used_in_version
}

const calendar_key_used_in_version = (gtfs_json, calendar_key) => {
  let calendar_key_used_in_version = false
  // Check if calendar_key used in trips
  Object.keys(gtfs_json.trips).forEach((trip_key) => {
    const trip = gtfs_json.trips[trip_key]
    if (trip.calendar_key.toString() === calendar_key.toString()) {
      calendar_key_used_in_version = true
    }
  })
  // Check if calendar_key used in calendar_date
  Object.keys(gtfs_json.calendar_dates).forEach((calendar_date_key) => {
    const calendar_date = gtfs_json.calendar_dates[calendar_date_key]
    if (calendar_date.calendar_key.toString() === calendar_key.toString()) {
      calendar_key_used_in_version = true
    }
  })
  // Check if calendar_key used in trips
  Object.keys(gtfs_json.trips).forEach((trip_key) => {
    const trip = gtfs_json.trips[trip_key]
    if (trip.calendar_key.toString() === calendar_key.toString()) {
      calendar_key_used_in_version = true
    }
  })
  return calendar_key_used_in_version
}

const route_key_used_in_version = (gtfs_json, route_key) => {
  let route_key_used_in_version = false
  // Check if route_key used in trips
  Object.keys(gtfs_json.trips).forEach((trip_key) => {
    const trip = gtfs_json.trips[trip_key]
    if (trip.route_key.toString() === route_key.toString()) {
      route_key_used_in_version = true
    }
  })
  // Check if route_key used in fare rules
  Object.keys(gtfs_json.fare_rules).forEach((fare_rule_key) => {
    const fare_rule = gtfs_json.fare_rules[fare_rule_key]
    if (fare_rule.route_key.toString() === route_key.toString()) {
      route_key_used_in_version = true
    }
  })
  return route_key_used_in_version
}

const fare_attribute_key_in_version = (gtfs_json, fare_attribute_key) => {
  let fare_attribute_key_in_version = false
  // Check if fare_attribute_key used in fare rules
  Object.keys(gtfs_json.fare_rules).forEach((fare_rule_key) => {
    const fare_rule = gtfs_json.fare_rules[fare_rule_key]
    if (
      fare_rule.fare_attribute_key.toString() === fare_attribute_key.toString()
    ) {
      fare_attribute_key_in_version = true
    }
  })
  return fare_attribute_key_in_version
}

const stop_key_used_in_version = (gtfs_json, stop_key) => {
  let stop_key_used_in_version = false
  // Check if stop_key used in stop_times
  Object.keys(gtfs_json.stop_times).forEach((stop_time_key) => {
    const stop_time = gtfs_json.stop_times[stop_time_key]
    if (stop_time.stop_key.toString() === stop_key.toString()) {
      stop_key_used_in_version = true
    }
  })
  return stop_key_used_in_version
}

const agency_key_used_in_version = (gtfs_json, agency_key) => {
  let agency_key_used_in_version = false
  // Check if agency_key used in routes
  Object.keys(gtfs_json.routes).forEach((route_key) => {
    const route = gtfs_json.routes[route_key]
    if (route.agency_key.toString() === agency_key.toString()) {
      agency_key_used_in_version = true
    }
  })
  return agency_key_used_in_version
}

module.exports = {
  route_key_used_in_version,
  trip_key_used_in_version,
  shape_key_used_in_version,
  calendar_key_used_in_version,
  fare_attribute_key_in_version,
  stop_key_used_in_version,
  agency_key_used_in_version,
}
