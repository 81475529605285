import React, { Fragment } from "react"
import Feeds from "./Feeds"
import { Grid, Link, Typography } from "@mui/material"
import Subscribe from "./Subscribe"
import { Bubble } from "../Background/Bubble"

const GtfsGuide = () => {
  return (
    <Fragment>
      <Typography variant="h5" padding={2}>
        GTFS Specification
      </Typography>
      <Typography margin={2}>
        Need some more information on the GTFS specification? Find it{" "}
        <Link target="_blank" href="https://developers.google.com/transit/gtfs">
          here
        </Link>
        .
      </Typography>
    </Fragment>
  )
}

const Home = () => {
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item>
        <Bubble>
          <GtfsGuide />
        </Bubble>
      </Grid>
      <Grid item>
        <Bubble>
          <Feeds />
        </Bubble>
      </Grid>
      <Grid item>
        <Bubble>
          <Subscribe />
        </Bubble>
      </Grid>
    </Grid>
  )
}

export default Home
