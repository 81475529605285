import React, { useState, Fragment, useEffect } from "react"
import Map, { Source, Layer } from "react-map-gl"
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import assign_key from "../utils/assignKey"
import { shape_key_used_in_version } from "../validation/crossValidation"
import DeleteIcon from "@mui/icons-material/Delete"
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter"
import TimelineIcon from "@mui/icons-material/Timeline"
import KeyboardIcon from "@mui/icons-material/Keyboard"
import config from "../../../../config.json"
import {
  stops_to_geo,
  shapes_to_geo_line,
  shapes_to_geo_points,
} from "../../Utils/conversion"
import {
  shapes_line_style,
  shape_point_style,
  make_stops_style,
  make_stops_label_style,
  box,
} from "../../Utils/styles"
import { determine_initial_view_state } from "../../Utils/map"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"

const ShapeDialog = ({
  shapes,
  set_version_changes,
  version,
  set_version,
  map,
  shape_key,
  set_shape_key,
  set_mode,
}) => {
  const [new_shape_id, set_new_shape_id] = useState("")
  const [delete_shape_key, set_delete_shape_key] = useState(null)

  const create_shape = () => {
    if (!new_shape_id) {
      return
    }
    set_version_changes((version_changes) => {
      let updated_version_changes = [...version_changes]
      updated_version_changes.unshift({
        gtfs_type: "SHAPE",
        action_type: "CREATE",
        new_values: { new_shape_id },
        old_values: {},
      })
      return updated_version_changes
    })

    const new_shape = {
      shape_id: new_shape_id,
      coordinates: [],
    }
    const new_key = assign_key(version.gtfs_json.shapes)
    let updated_version = { ...version }
    updated_version.gtfs_json.shapes[new_key] = new_shape
    set_version(updated_version)
    set_new_shape_id("")
    set_shape_key(new_key)
    set_mode("add_end")
  }

  return (
    <Fragment>
      <Card
        style={{
          zIndex: 1000,
          position: "absolute",
          width: "200px",
          top: "80px",
          right: "5px",
        }}
      >
        <div style={box}>
          <Typography>Current Shape</Typography>
          <Select
            value={shape_key === null ? "" : shape_key}
            variant="standard"
            fullWidth
            onChange={(event) => {
              set_shape_key(event.target.value)
              let selected_shape = shapes[event.target.value]
              const num_coords = selected_shape.coordinates.length
              if (num_coords === 0) {
                return
              }
              const lat_sum = selected_shape.coordinates
                .map((coord) => parseFloat(coord.shape_pt_lat))
                .reduce((a, b) => a + b, 0)
              const lon_sum = selected_shape.coordinates
                .map((coord) => parseFloat(coord.shape_pt_lon))
                .reduce((a, b) => a + b, 0)
              const new_location = [lon_sum / num_coords, lat_sum / num_coords]
              map?.flyTo({ center: new_location, duration: 2000 })
            }}
          >
            {Object.keys(shapes).map((key) => {
              const shape = shapes[key]
              return (
                <MenuItem key={key} value={key}>
                  {shape.shape_id}
                </MenuItem>
              )
            })}
          </Select>

          <Button
            variant="outlined"
            fullWidth
            color="primary"
            onClick={() => {
              set_delete_shape_key(shape_key)
            }}
          >
            Delete Shape
          </Button>
        </div>
        <div style={box}>
          <ValidatorForm onSubmit={create_shape} onError={(errors) => {}}>
            <TextValidator
              label={"Shape Id*"}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                set_new_shape_id(e.target.value)
              }}
              name="stop_id"
              type="text"
              fullWidth
              value={new_shape_id}
              validators={["required"]}
              errorMessages={["This field is required"]}
              margin="dense"
              variant="standard"
            />
            <Button
              variant="outlined"
              type={"submit"}
              color="primary"
              fullWidth
            >
              Create Shape
            </Button>
          </ValidatorForm>
        </div>
      </Card>
      <DeleteDialog
        delete_shape_key={delete_shape_key}
        set_delete_shape_key={set_delete_shape_key}
        version={version}
        set_version={set_version}
        shape_key={shape_key}
        set_shape_key={set_shape_key}
        set_version_changes={set_version_changes}
      />
    </Fragment>
  )
}

const DeleteDialog = ({
  version,
  set_version,
  shape_key,
  set_shape_key,
  set_version_changes,
  set_delete_shape_key,
  delete_shape_key,
}) => {
  if (delete_shape_key === null) {
    return null
  }

  const handle_close = () => {
    set_delete_shape_key(null)
  }

  const delete_shape = async () => {
    if (shape_key === null) {
      return
    }
    if (shape_key_used_in_version(version.gtfs_json, shape_key)) {
      alert("Shape used elsewhere in version and can not be deleted.")
      return
    }

    set_version_changes((version_changes) => {
      let updated_version_changes = [...version_changes]
      const shape_id = version.gtfs_json.shapes[shape_key].shape_id
      updated_version_changes.unshift({
        gtfs_type: "SHAPE",
        action_type: "DELETE",
        new_values: {},
        old_values: { shape_id },
      })
      return updated_version_changes
    })
    let updated_version = { ...version }
    delete updated_version.gtfs_json.shapes[shape_key]
    set_shape_key(null)
    set_version(updated_version)
    handle_close()
  }

  let current_item = version.gtfs_json.shapes[delete_shape_key]
  const info_items = [{ label: "Shape Id", key: "shape_id" }]

  return (
    <Dialog
      open={delete_shape_key === null ? false : true}
      onClose={handle_close}
    >
      <DialogTitle>Delete Shape</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this entry?</Typography>
        <Grid style={{ width: "300px" }} container spacing={0}>
          {info_items.map((item, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={6}>
                  <Typography>{item.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{current_item[item.key]}</Typography>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handle_close} color="primary">
          Cancel
        </Button>
        <Button variant="outlined" onClick={delete_shape} color="primary">
          Delete Shape
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ModeSelect = ({ mode, set_mode, show_stops, set_show_stops }) => {
  return (
    <Card
      style={{
        zIndex: 1000,
        position: "absolute",
        bottom: "25px",
        right: "5px",
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid>
          <ToggleButtonGroup
            onChange={() => {
              set_show_stops(!show_stops)
            }}
            exclusive
            size="small"
            value={show_stops ? "true" : null}
          >
            <ToggleButton value="true">
              <Tooltip title="Show Stop Names" placement="top">
                <KeyboardIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            size="small"
            value={mode}
            exclusive
            onChange={(event, value) => {
              set_mode(value)
            }}
          >
            <ToggleButton value="add_center">
              <Tooltip title="Add Coordinate Between Points" placement="top">
                <VerticalAlignCenterIcon />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="remove">
              <Tooltip title="Remove Coordinate" placement="top">
                <DeleteIcon />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="add_end">
              <Tooltip title="Add Coordinate At End" placement="top">
                <TimelineIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Card>
  )
}

const EditStop = (props) => {
  const { version, set_version, set_version_changes } = props
  const { stops, shapes } = version.gtfs_json

  const [map, set_map] = useState(null)
  const [mode, set_mode] = useState(null)
  const [shape_key, set_shape_key] = useState(null)
  const [show_stops, set_show_stops] = useState(true)

  const remove_coordinate_location = (click) => {
    const { index } = click.features[0].properties
    set_version((version) => {
      let updated_version = { ...version }
      updated_version.gtfs_json.shapes[shape_key].coordinates.splice(index, 1)
      updated_version.gtfs_json.shapes[shape_key].coordinates =
        updated_version.gtfs_json.shapes[shape_key].coordinates.map(
          (coordinate, i) => {
            coordinate.shape_pt_sequence = i + 1
            return coordinate
          }
        )
      return updated_version
    })
    add_update_to_changes()
  }

  const add_coordinate_location = (
    insertion_index,
    shape_pt_lat,
    shape_pt_lon
  ) => {
    set_version((version) => {
      let updated_version = { ...version }
      let new_coordinates = version.gtfs_json.shapes[shape_key].coordinates
      const new_coordinate = { shape_pt_lat, shape_pt_lon }
      new_coordinates.splice(insertion_index, 0, new_coordinate)
      new_coordinates.map((coordinate, i) => {
        coordinate.shape_pt_sequence = i
        return coordinate
      })
      updated_version.gtfs_json.shapes[shape_key].coordinates = new_coordinates
      return updated_version
    })
    add_update_to_changes()
  }

  const add_point_to_end = (click) => {
    const shape_pt_lat = click.lngLat.lat
    const shape_pt_lon = click.lngLat.lng
    const insertion_index =
      version.gtfs_json.shapes[shape_key].coordinates.length
    add_coordinate_location(insertion_index, shape_pt_lat, shape_pt_lon)
    add_update_to_changes()
  }

  const add_update_to_changes = () => {
    set_version_changes((version_changes) => {
      let updated_version_changes = [...version_changes]
      const shape_id = version.gtfs_json.shapes[shape_key].shape_id
      updated_version_changes.unshift({
        gtfs_type: "SHAPE",
        action_type: "UPDATE",
        new_values: { shape_id },
        old_values: {},
      })
      return updated_version_changes
    })
  }

  useEffect(() => {
    if (map) {
      let shape_key = null
      let point_id = null
      let prev_seg_start = null
      let sub_segment_end = null
      const canvas = map.getCanvasContainer()

      const on_move = (e) => {
        const { lng, lat } = e.lngLat
        canvas.style.cursor = "grabbing"

        const points_source = map.getSource("shape_points")
        points_source.updateData({
          id: point_id,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
        })

        const feature_collection = {
          type: "FeatureCollection",
          features: [],
        }
        if (prev_seg_start) {
          feature_collection.features.push({
            id: point_id - 1,
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [prev_seg_start, [lng, lat]],
            },
          })
        }
        if (sub_segment_end) {
          feature_collection.features.push({
            id: point_id,
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [[lng, lat], sub_segment_end],
            },
          })
        }
        const lines_source = map.getSource("shape_lines")
        lines_source.updateData(feature_collection)
      }

      const on_up = (e) => {
        canvas.style.cursor = ""
        map.off("mousemove", on_move)
        const { lat, lng } = e.lngLat
        set_version((version) => {
          let updated_version = { ...version }
          updated_version.gtfs_json.shapes[shape_key].coordinates[
            point_id
          ].shape_pt_lat = lat
          updated_version.gtfs_json.shapes[shape_key].coordinates[
            point_id
          ].shape_pt_lon = lng
          return updated_version
        })
      }

      map.on("mousedown", "shape_points", (e) => {
        e.preventDefault()
        canvas.style.cursor = "grab"
        shape_key = e.features[0].properties.key
        point_id = e.features[0].id
        const lines_source = map.getSource("shape_lines")
        const { features } = lines_source._data
        prev_seg_start = features[point_id - 1]
          ? features[point_id - 1].geometry.coordinates[0]
          : null
        sub_segment_end = features[point_id]
          ? features[point_id].geometry.coordinates[1]
          : null
        map.on("mousemove", on_move)
        map.once("mouseup", on_up)
      })

      map.on("mouseenter", "shape_points", () => {
        canvas.style.cursor = "move"
      })

      map.on("mouseleave", "shape_points", () => {
        canvas.style.cursor = ""
      })
    }
  }, [map, set_version, shape_key])

  return (
    <Fragment>
      <Map
        ref={(ref) => set_map(ref)}
        style={{ width: "100%", height: "calc(100vh - 64px)" }}
        mapboxAccessToken={config.mapbox_token}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        initialViewState={determine_initial_view_state(stops)}
        interactiveLayerIds={["shape_points", "shape_lines"]}
        onClick={(click) => {
          if (shape_key === null) {
            return
          }
          if (mode === "add_end") {
            add_point_to_end(click)
          }
          if (click.features.length === 0) {
            return
          }
          if (mode === "remove") {
            remove_coordinate_location(click)
          }
          if (mode === "add_center") {
            const { index } = click.features[0].properties
            const { lat, lng } = click.lngLat
            add_coordinate_location(index, lat, lng)
          }
        }}
      >
        <Source
          type="geojson"
          data={shapes_to_geo_line(shapes, shape_key)}
          id="shape_lines"
          dynamic={true}
        >
          <Layer {...shapes_line_style} />
        </Source>
        <Source
          type="geojson"
          data={shapes_to_geo_points(shapes, shape_key)}
          id="shape_points"
          dynamic={true}
        >
          <Layer {...shape_point_style} />
        </Source>

        <Source type="geojson" data={stops_to_geo(stops)} id="stops">
          <Layer {...make_stops_style(show_stops)} />
          <Layer {...make_stops_label_style(show_stops)} />
        </Source>
      </Map>
      <ShapeDialog
        shapes={shapes}
        set_version_changes={set_version_changes}
        version={version}
        set_version={set_version}
        map={map}
        shape_key={shape_key}
        set_shape_key={set_shape_key}
        set_mode={set_mode}
      />
      <ModeSelect
        mode={mode}
        set_mode={set_mode}
        show_stops={show_stops}
        set_show_stops={set_show_stops}
      />
    </Fragment>
  )
}

export default EditStop
