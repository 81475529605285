const max_date = (date) => {
  var today = new Date()
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0')
  var yyyy = today.getFullYear()
  const max_date = `${yyyy + 2}-${mm}-${dd}`
  return max_date
}

export default max_date
