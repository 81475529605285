const route_id_html = (
  <span>
    <p>Identifies a route.</p>
  </span>
)

const route_short_name_html = (
  <span>
    <p>
      Short name of a route. This will often be a short, abstract identifier
      like "32", "100X", or "Green" that riders use to identify a route, but
      which doesn't give any indication of what places the route serves.
    </p>
  </span>
)

const route_long_name_html = (
  <span>
    <p>
      Full name of a route. This name is generally more descriptive than the
      route_short_name and often includes the route's destination or stop.
    </p>
  </span>
)

const route_type_html = (
  <span>
    <p>
      Indicates the type of transportation used on a route. Valid options are:
    </p>
    <p>
      Tram, Streetcar, Light rail - Any light rail or street level system within
      a metropolitan area.
    </p>
    <p>
      Subway, Metro - Any underground rail system within a metropolitan area.
    </p>
    <p>Rail - Used for intercity or long-distance travel.</p>
    <p>Bus - Used for short- and long-distance bus routes.</p>
    <p>Ferry - Used for short- and long-distance boat service.</p>
    <p>
      Cable tram - Used for street-level rail cars where the cable runs beneath
      the vehicle, e.g., cable car in San Francisco.
    </p>
    <p>
      Aerial lift, suspended cable car (e.g., gondola lift, aerial tramway) -
      Cable transport where cabins, cars, gondolas or open chairs are suspended
      by means of one or more cables.
    </p>
    <p>Funicular - Any rail system designed for steep inclines.</p>
    <p>
      Trolleybus - Electric buses that draw power from overhead wires using
      poles.
    </p>
    <p>
      Monorail - Railway in which the track consists of a single rail or a beam.
    </p>
  </span>
)

export {
  route_id_html,
  route_type_html,
  route_short_name_html,
  route_long_name_html,
}
