import React, { Fragment, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import axios from "axios"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import { Box, Button } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Alert from "@mui/material/Alert"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import { useNavigate } from "react-router-dom"
import config from "../../config.json"

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}))

function NewFeed(props) {
  const { set_feeds } = props
  const initial_form_data = {
    description: "",
    name: "",
  }

  const [alerts, set_alerts] = useState([])
  const [form_data, set_form_data] = useState(initial_form_data)
  const [open_dialog, set_open_dialog] = useState(false)

  const handleClose = () => {
    set_alerts([])
    set_form_data(initial_form_data)
    set_open_dialog(false)
  }

  const create_feed = async () => {
    try {
      const { data } = await axios.post(config.api_url + `/feed`, form_data)
      set_feeds((feeds) => {
        let updated_feeds = [...feeds]
        updated_feeds.push(data)
        return updated_feeds
      })
      handleClose()
    } catch (error) {
      console.log(error)
      if (error.response.data) {
        alerts(error.response.data)
      }
    }
  }

  const handleValueChange = (event) => {
    const name = event.target.name
    const new_value = event.target.value
    var new_state = {
      ...form_data,
      [name]: new_value,
    }
    set_form_data(new_state)
  }

  return (
    <Fragment>
      <Dialog
        open={open_dialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography id="form-dialog-title" variant="h5">
            Create a new GTFS Feed
          </Typography>
          <DialogContentText>
            Fill out the basic information regarding the Feed and click 'Create
            Feed' to add a new blank Feed to the system.
          </DialogContentText>
          <ValidatorForm onSubmit={create_feed} onError={(errors) => {}}>
            <TextValidator
              value={form_data.name}
              name="name"
              type="text"
              fullWidth
              label="Name"
              onChange={handleValueChange}
              validators={["required"]}
              errorMessages={["This field is required"]}
              margin="dense"
              variant="standard"
            />
            <TextValidator
              value={form_data.description}
              name="description"
              type="text"
              fullWidth
              label="Description"
              onChange={handleValueChange}
              validators={["required"]}
              errorMessages={["This field is required"]}
              margin="dense"
              variant="standard"
            />

            <div style={{ marginTop: "10px" }}>
              {alerts.map((error, index) => {
                return (
                  <Alert key={index} severity="error">
                    {error}
                  </Alert>
                )
              })}
            </div>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button variant="outlined" type={"submit"} color="primary">
                Create Feed
              </Button>
            </DialogActions>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        <Button
          variant="outlined"
          onClick={() => {
            set_open_dialog(true)
          }}
        >
          New Feed
        </Button>
      </Box>
    </Fragment>
  )
}

const Feeds = () => {
  const [feeds, set_feeds] = useState([])
  const [loading, set_loading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    set_loading(true)
    axios
      .get(config.api_url + "/feed/all")
      .then((response) => {
        set_feeds(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        set_loading(false)
      })
  }, [])

  const classes = useStyles()

  if (loading) {
    return (
      <div
        style={{
          marginTop: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <Fragment>
      <TableContainer>
        <Typography variant="h5" margin={2}>
          Your GTFS Feeds
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Description</b>
              </TableCell>
              <TableCell align="center">
                <b>Versions</b>
              </TableCell>
              <TableCell align="center">
                <b>Work in Progress</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feeds.map((feed) => {
              const { feed_pk, name, description, version_count, wip_count } =
                feed
              return (
                <TableRow
                  style={{ cursor: "pointer" }}
                  key={feed_pk}
                  onClick={() => {
                    navigate(`/feed/${feed_pk}`)
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell align="center">{description}</TableCell>
                  <TableCell align="center">{version_count}</TableCell>
                  <TableCell align="center"> {wip_count}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <NewFeed set_feeds={set_feeds} />
    </Fragment>
  )
}

export default Feeds
