import { Fragment, useEffect, useState } from "react"
import { determine_initial_view_state } from "../../Utils/map"
import Map, { Source, Layer } from "react-map-gl"
import config from "../../../../config.json"
import { shapes_to_geo, stops_to_geo } from "../../Utils/conversion"
import {
  shapes_line_style,
  make_stops_label_style,
  make_stops_style,
} from "../../Utils/styles"
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import produce_label from "../Tooltips/produce_label"
import { stop_name_html, stop_id_html } from "../Tooltips/stops"
import assign_key from "../utils/assignKey"
import DeleteIcon from "@mui/icons-material/Delete"
import CreateIcon from "@mui/icons-material/Create"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { stop_key_used_in_version } from "../validation/crossValidation"
import KeyboardIcon from "@mui/icons-material/Keyboard"

const CreateStopDialog = (props) => {
  const { create_stop, set_create_stop, set_version, set_version_changes } =
    props

  if (!create_stop) {
    return null
  }

  const create_new_stop = async () => {
    set_version((version) => {
      let updated_version = { ...version }
      const new_key = assign_key(updated_version.gtfs_json.stops)
      updated_version.gtfs_json.stops[new_key] = create_stop
      return updated_version
    })
    set_version_changes((changes) => {
      let updated_changes = [...changes]
      updated_changes.unshift({
        gtfs_type: "STOP",
        action_type: "CREATE",
        new_values: create_stop,
        old_values: {},
      })
      return updated_changes
    })
    handleClose()
  }

  const handleClose = () => {
    set_create_stop(null)
  }

  const handle_value_change = (event) => {
    set_create_stop((stop) => {
      let updated_stop = { ...stop }
      const name = event.target.name
      let new_value = event.target.value
      if (name === "stop_name") {
        new_value = new_value.replace(/[^a-zA-Z0-9 ./@()&+-_']/g, "")
      } else if (name === "stop_id") {
        new_value = new_value.replace(/[^a-zA-Z0-9 _-]/g, "")
      }
      updated_stop[name] = new_value
      return updated_stop
    })
  }

  return (
    <Dialog
      open={create_stop ? true : false}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create a New Stop</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create a stop at the current location.
        </DialogContentText>

        <ValidatorForm onSubmit={create_new_stop} onError={(errors) => {}}>
          <TextValidator
            label={produce_label("Stop Id*", stop_id_html)}
            InputLabelProps={{ shrink: true }}
            onChange={handle_value_change}
            name="stop_id"
            type="text"
            fullWidth
            value={create_stop.stop_id}
            validators={["required"]}
            errorMessages={["This field is required"]}
            margin="dense"
            variant="standard"
          />
          <TextValidator
            label={produce_label("Stop Name*", stop_name_html)}
            InputLabelProps={{ shrink: true }}
            onChange={handle_value_change}
            name="stop_name"
            type="text"
            fullWidth
            value={create_stop.stop_name}
            validators={["required"]}
            errorMessages={["This field is required"]}
            margin="dense"
            variant="standard"
          />
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="outlined" type={"submit"} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  )
}

const handle_map_click = (
  click,
  mode,
  set_create_stop,
  set_delete_stop,
  set_edit_stop
) => {
  if (!mode) {
    return
  }
  if (mode === "create") {
    const { lat, lng } = click.lngLat
    set_create_stop({
      stop_lon: lng,
      stop_lat: lat,
      stop_id: "",
      stop_name: "",
    })
  }
  const { features } = click
  if (features.length === 0) {
    return
  }
  if (mode === "delete") {
    set_delete_stop(features[0].properties)
  }
  if (mode === "edit") {
    set_edit_stop(features[0].properties)
  }
}

const ModeSelect = ({
  mode,
  set_mode,
  show_stop_names,
  set_show_stop_names,
}) => {
  return (
    <Card
      style={{
        zIndex: 1000,
        position: "absolute",
        bottom: "25px",
        right: "5px",
      }}
    >
      <ToggleButtonGroup
        onChange={() => {
          set_show_stop_names(!show_stop_names)
        }}
        exclusive
        size="small"
        value={show_stop_names ? "yes" : null}
      >
        <ToggleButton value="yes">
          <Tooltip title="Show Stop Names" placement="top">
            <KeyboardIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        size="small"
        value={mode}
        exclusive
        onChange={(event, value) => {
          set_mode(value)
        }}
      >
        <ToggleButton value="create">
          <Tooltip title="Create Stop" placement="top">
            <AddCircleOutlineIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="delete">
          <Tooltip title="Delete Stop" placement="top">
            <DeleteIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="edit">
          <Tooltip title="Edit Stop" placement="top">
            <CreateIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Card>
  )
}

const DeleteStopDialog = ({
  delete_stop,
  set_delete_stop,
  version,
  set_version,
  set_version_changes,
}) => {
  if (!delete_stop) {
    return null
  }

  const handleClose = () => {
    set_delete_stop(null)
  }

  const remove_stop = async () => {
    if (stop_key_used_in_version(version.gtfs_json, delete_stop.key)) {
      return alert("Stop used elsewhere in version and can not be deleted.")
    }
    set_version_changes((changes) => {
      let updated_changes = [...changes]
      updated_changes.unshift({
        gtfs_type: "STOP",
        action_type: "DELETE",
        new_values: {},
        old_values: version.gtfs_json.stops[delete_stop.key],
      })
      return updated_changes
    })
    set_version((version) => {
      let updated_version = { ...version }
      delete updated_version.gtfs_json.stops[delete_stop.key]
      return updated_version
    })
    handleClose()
  }

  const info_items = [
    { label: "Stop Name", key: "stop_name" },
    { label: "Stop Id", key: "stop_id" },
    { label: "Stop Latitude", key: "stop_lat" },
    { label: "Stop Longitude", key: "stop_lon" },
  ]

  return (
    <Dialog
      open={delete_stop ? true : false}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Delete Stop</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this entry?
        </DialogContentText>
        <Grid style={{ width: "300px" }} container spacing={0}>
          {info_items.map((item, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={6}>
                  <Typography>{item.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{delete_stop[item.key]}</Typography>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            remove_stop()
          }}
          color="primary"
        >
          Delete Entry
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const EditStopDialog = ({
  edit_stop,
  set_edit_stop,
  version,
  set_version,
  set_version_changes,
}) => {
  if (!edit_stop) {
    return null
  }

  const handle_value_change = (event) => {
    const name = event.target.name
    let new_value = event.target.value
    if (name === "stop_name") {
      new_value = new_value.replace(/[^a-zA-Z0-9 ./@()&+-_']/g, "")
    } else if (name === "stop_id") {
      new_value = new_value.replace(/[^a-zA-Z0-9 _-]/g, "")
    }
    set_edit_stop((edit_stop) => {
      let updated_edit_stop = { ...edit_stop }
      updated_edit_stop[name] = new_value
      return updated_edit_stop
    })
  }

  const update_stop = async () => {
    set_version_changes((changes) => {
      let updated_changes = [...changes]
      updated_changes.unshift({
        gtfs_type: "STOP",
        action_type: "UPDATE",
        new_values: edit_stop,
        old_values: version.gtfs_json.stops[edit_stop.key],
      })
      return updated_changes
    })

    set_version((version) => {
      let updated_version = { ...version }
      updated_version.gtfs_json.stops[edit_stop.key] = edit_stop
      return updated_version
    })

    handleClose()
  }

  const handleClose = () => {
    set_edit_stop(null)
  }

  return (
    <Dialog
      open={edit_stop ? true : false}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit/Delete Stop</DialogTitle>
      <DialogContent>
        <DialogContentText>Edit or delete an existing stop.</DialogContentText>
        <ValidatorForm onSubmit={update_stop} onError={(errors) => {}}>
          <TextValidator
            label={produce_label("Stop Id*", stop_id_html)}
            InputLabelProps={{ shrink: true }}
            onChange={handle_value_change}
            name="stop_id"
            type="text"
            fullWidth
            value={edit_stop.stop_id}
            validators={["required"]}
            errorMessages={["This field is required"]}
            margin="dense"
            variant="standard"
          />
          <TextValidator
            label={produce_label("Stop Name*", stop_name_html)}
            InputLabelProps={{ shrink: true }}
            onChange={handle_value_change}
            name="stop_name"
            type="text"
            fullWidth
            value={edit_stop.stop_name}
            validators={["required"]}
            errorMessages={["This field is required"]}
            margin="dense"
            variant="standard"
          />
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="outlined" type={"submit"} color="primary">
              Update
            </Button>
          </DialogActions>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  )
}

const StopsMap = ({ version, set_version, set_version_changes }) => {
  const [create_stop, set_create_stop] = useState(null)
  const [delete_stop, set_delete_stop] = useState(null)
  const [edit_stop, set_edit_stop] = useState(null)
  const [mode, set_mode] = useState(null)
  const [show_stop_names, set_show_stop_names] = useState(false)
  const [map, set_map] = useState(false)

  useEffect(() => {
    if (map) {
      const canvas = map.getCanvasContainer()
      let stop_key = null

      const on_move = (e) => {
        const coords = e.lngLat
        canvas.style.cursor = "grabbing"
        const source = map.getSource("stops_source")
        source.updateData({
          id: stop_key,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [coords.lng, coords.lat],
          },
        })
      }

      const on_up = (e) => {
        canvas.style.cursor = ""
        map.off("mousemove", on_move)
        const { lat, lng } = e.lngLat
        let updated_stop = { ...version.gtfs_json.stops[stop_key] }
        updated_stop.stop_lon = lng
        updated_stop.stop_lat = lat
        set_version((version) => {
          let updated_version = { ...version }
          updated_version.gtfs_json.stops[stop_key] = updated_stop
          return updated_version
        })
        set_version_changes((changes) => {
          let updated_changes = [...changes]
          updated_changes.unshift({
            gtfs_type: "STOP",
            action_type: "UPDATE",
            new_values: updated_stop,
            old_values: version.gtfs_json.stops[stop_key],
          })
          return updated_changes
        })
      }

      map.on("mousedown", "stops", (e) => {
        e.preventDefault()
        canvas.style.cursor = "grab"
        stop_key = e.features[0].properties.key
        map.on("mousemove", on_move)
        map.once("mouseup", on_up)
      })
      map.on("mouseenter", "stops", () => {
        canvas.style.cursor = "move"
      })
      map.on("mouseleave", "stops", () => {
        canvas.style.cursor = ""
      })
    }
  }, [map, set_version, set_version_changes, version.gtfs_json.stops])

  const { stops, shapes } = version.gtfs_json

  return (
    <Fragment>
      <Map
        ref={(ref) => set_map(ref)}
        style={{ width: "100%", height: "calc(100vh - 64px)" }}
        mapboxAccessToken={config.mapbox_token}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        initialViewState={determine_initial_view_state(stops)}
        interactiveLayerIds={["stops"]}
        onClick={(click) => {
          handle_map_click(
            click,
            mode,
            set_create_stop,
            set_delete_stop,
            set_edit_stop
          )
        }}
      >
        <Source type="geojson" data={shapes_to_geo(shapes)} id="shapes_source">
          <Layer {...shapes_line_style} />
        </Source>
        <Source
          type="geojson"
          data={stops_to_geo(stops)}
          id="stops_source"
          dynamic={true}
        >
          <Layer {...make_stops_style(true)} />
          <Layer {...make_stops_label_style(show_stop_names)} />
        </Source>
      </Map>

      <CreateStopDialog
        set_version={set_version}
        create_stop={create_stop}
        set_create_stop={set_create_stop}
        set_version_changes={set_version_changes}
      />

      <DeleteStopDialog
        delete_stop={delete_stop}
        set_delete_stop={set_delete_stop}
        version={version}
        set_version={set_version}
        set_version_changes={set_version_changes}
      />

      <EditStopDialog
        edit_stop={edit_stop}
        set_edit_stop={set_edit_stop}
        version={version}
        set_version={set_version}
        set_version_changes={set_version_changes}
      />

      <ModeSelect
        mode={mode}
        set_mode={set_mode}
        show_stop_names={show_stop_names}
        set_show_stop_names={set_show_stop_names}
      />
    </Fragment>
  )
}

export default StopsMap
