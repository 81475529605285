import { Grid } from "@mui/material"
import React, { useState } from "react"
import { Bubble } from "../../Background/Bubble"
import WorkInProgress from "./WorkInProgress"
import Versions from "./Versions"
import Header from "./Header"
import { useParams } from "react-router-dom"

export default function FeedOverview() {
  let { feed_pk } = useParams()

  const [versions, set_versions] = useState([])

  return (
    <Grid container direction="row" justifyContent="center" padding={2}>
      <Grid item xs={12}>
        <Bubble>
          <Header feed_pk={feed_pk} num_versions={versions.length} />
        </Bubble>
      </Grid>
      <Grid item sm={6}>
        <Bubble>
          <WorkInProgress feed_pk={feed_pk} />
        </Bubble>
      </Grid>
      <Grid item sm={6}>
        <Bubble>
          <Versions
            versions={versions}
            set_versions={set_versions}
            feed_pk={feed_pk}
          />
        </Bubble>
      </Grid>
    </Grid>
  )
}
