const agency_id_html = (
  <span>
    <p>
      Identifies a transit brand which is often synonymous with a transit
      agency. Note that in some cases, such as when a single agency operates
      multiple separate services, agencies and brands are distinct. This
      document uses the term "agency" in place of "brand".
    </p>
  </span>
)

const agency_name_html = (
  <span>
    <p>Full name of the transit agency.</p>
  </span>
)

const agency_url_html = (
  <span>
    <p>URL of the transit agency.</p>
  </span>
)

const agency_timezone_html = (
  <span>
    <p>
      Timezone where the transit agency is located. If multiple agencies are
      specified in the feed, each must have the same agency_timezone.
    </p>
  </span>
)

const agency_lang_html = (
  <span>
    <p>
      Primary language used by this transit agency. This field helps GTFS
      consumers choose capitalization rules and other language-specific settings
      for the feed.
    </p>
  </span>
)

const agency_phone_html = (
  <span>
    <p>
      A voice telephone number for the specified agency. This field is a string
      value that presents the telephone number as typical for the agency's
      service area. It can and should contain punctuation marks to group the
      digits of the number. Dialable text (for example, TriMet's 503-238-RIDE)
      is permitted, but the field must not contain any other descriptive text.
    </p>
  </span>
)

export {
  agency_id_html,
  agency_name_html,
  agency_url_html,
  agency_timezone_html,
  agency_lang_html,
  agency_phone_html,
}
