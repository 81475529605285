const date_html = (
  <span>
    <p>Date when service exception occurs.</p>
  </span>
)

const exception_type_html = (
  <span>
    <p>
      Indicates whether service is available on the date specified in the date
      field. Valid options are:
    </p>
    <p>Added - Service has been added for the specified date.</p>
    <p>Removed - Service has been removed for the specified date.</p>
  </span>
)

const service_id_html = (
  <span>
    <p>
      Identifies a set of dates when a service exception occurs for one or more
      routes.
    </p>
  </span>
)

export { date_html, exception_type_html, service_id_html }
