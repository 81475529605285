const start_time_html = (
  <span>
    <p>
      Time at which the first vehicle departs from the first stop of the trip
      with the specified headway.
    </p>
  </span>
)

const end_time_html = (
  <span>
    <p>
      Time at which service changes to a different headway (or ceases) at the
      first stop in the trip.
    </p>
  </span>
)

const headway_secs_html = (
  <span>
    <p>
      Time, in seconds, between departures from the same stop (headway) for the
      trip, during the time interval specified by start_time and end_time.
      Multiple headways for the same trip are allowed, but may not overlap. New
      headways may start at the exact time the previous headway ends.
    </p>
  </span>
)

const exact_times_html = (
  <span>
    <p>
      Indicates the type of service for a trip. See the file description for
      more information. Valid options are:
    </p>
    <p>Frequency - Frequency-based trips.</p>
    <p>
      Schedule - Schedule-based trips with the exact same headway throughout the
      day. In this case the end_time value must be greater than the last desired
      trip start_time but less than the last desired trip start_time +
      headway_secs.
    </p>
  </span>
)

const trip_id_html = (
  <span>
    <p>Identifies a trip to which the specified headway of service applies.</p>
  </span>
)

export {
  start_time_html,
  end_time_html,
  headway_secs_html,
  exact_times_html,
  trip_id_html,
}
