import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import { Avatar, Button, Grid, Link as MuiLink, Tooltip } from "@mui/material"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Lottie from "react-lottie-player"
import animationData from "./bus.json"
import { useNavigate } from "react-router-dom"
import { useGoogleLogin } from "@react-oauth/google"
import axios from "axios"
import config from "../../config.json"
import setHeaders from "../Feed/EditGtfs/utils/setHeaders"

const Message = () => {
  return (
    <React.Fragment>
      <Typography
        textAlign="left"
        variant="h2"
        sx={{
          color: "primary.main",
        }}
      >
        Welcome
      </Typography>
      <Typography variant="h5" textAlign="left">
        Let's get you logged in so you can continue maintaining your data!
      </Typography>
    </React.Fragment>
  )
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <MuiLink color="inherit" href="https://www.csir.co.za/">
        CSIR
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default function SignIn() {
  const navigate = useNavigate()

  const login = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      axios
        .post(config.api_url + "/login/google", {
          access_token,
        })
        .then(({ data }) => {
          setHeaders(data.token)
          localStorage.setItem("token", data.token)
          navigate("/home")
        })
        .catch((err) => console.log(err))
    },
    onError: (error) => console.log("Login Failed:", error),
  })

  return (
    <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
      <Paper style={{ width: "420px" }}>
        <CssBaseline />
        <Box
          sx={{
            my: 2,
            mx: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Message />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Lottie
              style={{ width: "100%" }}
              loop
              animationData={animationData}
              play
              speed={0.4}
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            />
          </div>
          <Button
            variant="outlined"
            onClick={() => login()}
            style={{ padding: 0 }}
            startIcon={<Avatar src={"/images/google.png"} />}
          >
            Sign in with Google
          </Button>
          <Grid container justifyContent="flex-end">
            <Tooltip
              variant="caption"
              style={{ marginTop: 20, float: "right" }}
              title="Google login is one of the safest logins available. We are not able to implement a safer login than Google. We ensure that we do not share your data on this site with Google."
            >
              <Typography style={{ cursor: "pointer", padding: 10 }}>
                Why do we use Google sign-in?
              </Typography>
            </Tooltip>
          </Grid>

          <Copyright sx={{ mt: 1 }} />
        </Box>
      </Paper>
    </Box>
  )
}
