import * as React from "react"
import PropTypes from "prop-types"
import { Fragment } from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
// import { createTheme } from "@mui/material/styles"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded"
import Hero from "./components/Hero"
import Highlights from "./components/Highlights"
import Pricing from "./components/Pricing"
import Features from "./components/Features"
import FAQ from "./components/FAQ"
// import getLPTheme from "./getLPTheme"

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100dvw",
        position: "fixed",
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: "background.default",
          "& .Mui-selected": {
            pointerEvents: "none",
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: "20px", mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
}

export default function LandingPage() {
  // const showCustomTheme = false
  // const mode = "light"
  // const LPtheme = createTheme(getLPTheme(mode))
  // const defaultTheme = createTheme({ palette: { mode } })

  return (
    <Fragment>
      {/* <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}> */}
      <CssBaseline />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        <Divider />
        <Features />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
      </Box>
    </Fragment>
  )
}
