import * as React from "react"
import Box from "@mui/material/Box"
import { DataGrid } from "@mui/x-data-grid"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator"
import produce_label from "../Tooltips/produce_label"
import { Fragment } from "react"
import { useState } from "react"
import assign_key from "../utils/assignKey"
import formatDate from "../utils/formatDate"
import { useEffect } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import field_specification from "./field_specifications"
import {
  route_key_used_in_version,
  trip_key_used_in_version,
  shape_key_used_in_version,
  calendar_key_used_in_version,
  fare_attribute_key_in_version,
  stop_key_used_in_version,
  agency_key_used_in_version,
} from "../validation/crossValidation"

function DeleteDialog(props) {
  const {
    delete_row,
    set_delete_row,
    version,
    set_version,
    set_version_changes,
    fields,
    table,
  } = props

  if (!delete_row) {
    return null
  }

  const handle_close = () => {
    set_delete_row(null)
  }

  const on_submit = async () => {
    if (table === "routes") {
      if (route_key_used_in_version(version.gtfs_json, delete_row.key)) {
        return alert("This route is used elsewhere in the GTFS feed.")
      }
    } else if (table === "trips") {
      if (trip_key_used_in_version(version.gtfs_json, delete_row.key)) {
        return alert("This trip is used elsewhere in the GTFS feed.")
      }
    } else if (table === "shapes") {
      if (shape_key_used_in_version(version.gtfs_json, delete_row.key)) {
        return alert("This trip is used elsewhere in the GTFS feed.")
      }
    } else if (table === "calendar") {
      if (calendar_key_used_in_version(version.gtfs_json, delete_row.key)) {
        return alert("This calendar is used elsewhere in the GTFS feed.")
      }
    } else if (table === "fare_attributes") {
      if (fare_attribute_key_in_version(version.gtfs_json, delete_row.key)) {
        return alert("This fare attribute is used elsewhere in the GTFS feed.")
      }
    } else if (table === "stops") {
      if (stop_key_used_in_version(version.gtfs_json, delete_row.key)) {
        return alert("This stop is used elsewhere in the GTFS feed.")
      }
    } else if (table === "agencies") {
      if (agency_key_used_in_version(version.gtfs_json, delete_row.key)) {
        return alert("This agency is used elsewhere in the GTFS feed.")
      }
    }

    set_version_changes((version_changes) => {
      let updated_version_changes = [...version_changes]
      updated_version_changes.unshift({
        gtfs_type: table,
        action_type: "DELETE",
        new_values: {},
        old_values: delete_row,
      })
      return updated_version_changes
    })

    set_version((version) => {
      let updated_state = { ...version }
      delete updated_state.gtfs_json[table][delete_row.key]
      return updated_state
    })
    set_delete_row(null)
  }

  return (
    <Dialog
      open={delete_row ? true : false}
      onClose={handle_close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Delete Row</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this entry?
        </DialogContentText>

        <Grid style={{ width: "300px" }} container spacing={0}>
          {fields.map(
            ({ type, reference_table, field, label, options }, index) => {
              let value = delete_row[field]
              if (type === "reference") {
                const reference =
                  version.gtfs_json[reference_table][delete_row[field]]
                value = reference
                  ? reference[
                      Object.keys(reference).find((key) => key.includes("id"))
                    ]
                  : null
              } else if (type === "select") {
                const option = options.find(
                  (option) => option.value === delete_row[field]
                )
                value = option ? option.label : ""
              }

              return (
                <Fragment key={index}>
                  <Grid item xs={6}>
                    <Typography>{label}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{value}</Typography>
                  </Grid>
                </Fragment>
              )
            }
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handle_close} color="primary">
          Cancel
        </Button>
        <Button variant="outlined" onClick={on_submit} color="primary">
          Delete Entry
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function EditDialog({
  set_edit_row,
  edit_row,
  version,
  set_version,
  set_version_changes,
  fields,
  table,
}) {
  const [form_data, set_form_data] = useState(null)

  useEffect(() => {
    set_form_data(edit_row)
  }, [edit_row])

  if (!form_data) {
    return null
  }

  const handle_close = () => {
    set_edit_row(null)
  }

  const on_submit = () => {
    set_version_changes((version_changes) => {
      let updated_version_changes = [...version_changes]
      updated_version_changes.unshift({
        gtfs_type: table,
        action_type: "UPDATE",
        new_values: form_data,
        old_values: edit_row,
      })
      return updated_version_changes
    })

    set_version((version) => {
      var updated_state = { ...version }
      updated_state.gtfs_json[table][form_data.key] = form_data
      return updated_state
    })

    handle_close()
  }

  const handle_value_change = (event) => {
    const name = event.target.name
    let change_value = event.target.value

    if (name.includes("date")) {
      change_value = change_value.replace(/-/g, "")
    }

    var new_state = {
      ...form_data,
      [name]: change_value,
    }
    set_form_data(new_state)
  }

  return (
    <Dialog
      open={edit_row ? true : false}
      onClose={handle_close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Update Row</DialogTitle>
      <DialogContent>
        <DialogContentText>Update row information.</DialogContentText>
        <ValidatorForm onSubmit={on_submit} onError={(errors) => {}}>
          {fields.map(
            (
              {
                tooltip,
                label,
                field,
                required,
                type,
                options,
                reference_table,
                inputProps,
              },
              i
            ) => {
              if (["text", "number", "time", "date"].includes(type)) {
                return (
                  <TextValidator
                    key={i}
                    label={produce_label(label, tooltip, required)}
                    onChange={handle_value_change}
                    name={field}
                    fullWidth
                    type={type}
                    value={
                      type === "date"
                        ? formatDate(form_data[field])
                        : form_data[field]
                    }
                    validators={required ? ["required"] : []}
                    errorMessages={["This field is required"]}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    variant="standard"
                    inputProps={inputProps}
                  />
                )
              } else if (["select", "reference"].includes(type)) {
                return (
                  <SelectValidator
                    fullWidth
                    key={i}
                    label={produce_label(label, tooltip, required)}
                    InputLabelProps={{ shrink: true }}
                    onChange={handle_value_change}
                    name={field}
                    value={field in form_data ? form_data[field] : ""}
                    validators={required ? ["required"] : []}
                    errorMessages={["This field is required"]}
                    margin="dense"
                    variant="standard"
                    inputProps={inputProps}
                  >
                    {type === "select"
                      ? options.map(({ value, label }, i) => {
                          return (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          )
                        })
                      : Object.keys(version.gtfs_json[reference_table]).map(
                          (key) => {
                            const row = version.gtfs_json[reference_table][key]
                            const id_property = Object.keys(row).find((key) =>
                              key.includes("id")
                            )
                            return (
                              <MenuItem key={key} value={key}>
                                {row[id_property]}
                              </MenuItem>
                            )
                          }
                        )}
                  </SelectValidator>
                )
              } else {
                console.log(`Error: Type ${type} not implemented`)
                return null
              }
            }
          )}
          <DialogActions>
            <Button variant="outlined" onClick={handle_close} color="primary">
              Cancel
            </Button>
            <Button variant="outlined" type={"submit"} color="primary">
              Update
            </Button>
          </DialogActions>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  )
}

function CreateDialog(props) {
  const { version, set_version, set_version_changes, fields, table } = props
  const [form_data, set_form_data] = useState(null)

  const handle_close = () => {
    set_form_data(null)
  }

  const on_submit = () => {
    set_version_changes((version_changes) => {
      let updated_version_changes = [...version_changes]
      updated_version_changes.unshift({
        gtfs_type: table,
        action_type: "CREATE",
        new_values: form_data,
        old_values: {},
      })
      return updated_version_changes
    })

    set_version((version) => {
      var updated_state = { ...version }
      const new_key = assign_key(updated_state.gtfs_json[table])
      updated_state.gtfs_json[table][new_key] = { ...form_data }
      return updated_state
    })

    handle_close()
  }

  const handle_value_change = (event) => {
    const name = event.target.name
    let change_value = event.target.value

    if (name.includes("date")) {
      change_value = change_value.replace(/-/g, "")
    }

    var new_state = {
      ...form_data,
      [name]: change_value,
    }
    set_form_data(new_state)
  }

  const now = () => {
    const date = new Date()
    let day = String(date.getDate()).padStart(2, "0")
    let month = String(date.getMonth() + 1).padStart(2, "0")
    let year = date.getFullYear()
    return `${year}${month}${day}`
  }

  const make_initial_form_data = () => {
    const initial_form_data = {}
    fields.forEach(({ field, type, options }) => {
      if (type === "text" || type === "reference") {
        initial_form_data[field] = ""
      } else if (type === "date") {
        initial_form_data[field] = now()
      } else if (type === "number") {
        initial_form_data[field] = 0
      } else if (type === "select") {
        initial_form_data[field] = options[0].value
      } else if (type === "time") {
        initial_form_data[field] = "00:00:00"
      }
    })
    return initial_form_data
  }

  return (
    <Fragment>
      <Button
        fullWidth
        onClick={() => {
          set_form_data(make_initial_form_data())
        }}
      >
        Create
      </Button>
      {form_data ? (
        <Dialog open={form_data ? true : false} onClose={handle_close}>
          <DialogTitle id="form-dialog-title">Create Row</DialogTitle>
          <DialogContent>
            <DialogContentText>Create a new entry.</DialogContentText>

            <ValidatorForm onSubmit={on_submit} onError={(errors) => {}}>
              {fields.map(
                (
                  {
                    tooltip,
                    label,
                    field,
                    required,
                    type,
                    options,
                    reference_table,
                    inputProps,
                  },
                  i
                ) => {
                  if (["text", "number", "time", "date"].includes(type)) {
                    return (
                      <TextValidator
                        key={i}
                        label={produce_label(label, tooltip, required)}
                        onChange={handle_value_change}
                        name={field}
                        fullWidth
                        type={type}
                        value={
                          type === "date"
                            ? formatDate(form_data[field])
                            : form_data[field]
                        }
                        validators={required ? ["required"] : []}
                        errorMessages={["This field is required"]}
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        variant="standard"
                        inputProps={inputProps}
                      />
                    )
                  } else if (["select", "reference"].includes(type)) {
                    return (
                      <SelectValidator
                        fullWidth
                        key={i}
                        label={produce_label(label, tooltip, required)}
                        InputLabelProps={{ shrink: true }}
                        onChange={handle_value_change}
                        name={field}
                        value={form_data[field]}
                        validators={required ? ["required"] : []}
                        errorMessages={["This field is required"]}
                        margin="dense"
                        variant="standard"
                        inputProps={inputProps}
                      >
                        {type === "select"
                          ? options.map(({ value, label }, i) => {
                              return (
                                <MenuItem key={value} value={value}>
                                  {label}
                                </MenuItem>
                              )
                            })
                          : Object.keys(version.gtfs_json[reference_table]).map(
                              (key) => {
                                const row =
                                  version.gtfs_json[reference_table][key]
                                const id_property = Object.keys(row).find(
                                  (key) => key.includes("id")
                                )
                                return (
                                  <MenuItem key={key} value={key}>
                                    {row[id_property]}
                                  </MenuItem>
                                )
                              }
                            )}
                      </SelectValidator>
                    )
                  } else {
                    console.log(`Error: Type ${type} not implemented`)
                    return null
                  }
                }
              )}
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={handle_close}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button variant="outlined" type={"submit"} color="primary">
                  Create
                </Button>
              </DialogActions>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      ) : null}
    </Fragment>
  )
}

export default function GenericEditTable({
  version,
  set_version,
  set_version_changes,
  table,
}) {
  const [edit_row, set_edit_row] = useState(null)
  const [delete_row, set_delete_row] = useState(null)
  const fields = field_specification[table]

  const reference_fields = []
  const select_fields = []
  const columns = fields.map(
    ({ label, field, type, reference_table, options }) => {
      if (type === "reference") {
        const column_field = reference_table + "_id"
        reference_fields.push({
          column: column_field,
          field,
          reference_table,
        })
        field = column_field
      } else if (type === "select") {
        const column_field = field + "_id"
        select_fields.push({
          column: column_field,
          field,
          options,
        })
        field = column_field
      }
      return { headerName: label, field, flex: 1 }
    }
  )

  columns.push({
    field: "update",
    headerName: "Update",
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      return (
        <IconButton
          variant="outlined"
          onClick={() => {
            set_edit_row(row)
          }}
        >
          <EditIcon />
        </IconButton>
      )
    },
  })

  columns.push({
    field: "delete",
    headerName: "Delete",
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      return (
        <IconButton
          variant="outlined"
          onClick={() => {
            set_delete_row(row)
          }}
        >
          <DeleteIcon />
        </IconButton>
      )
    },
  })

  const rows = Object.keys(version.gtfs_json[table]).map((key) => {
    let values = { ...version.gtfs_json[table][key], key, id: key }
    reference_fields.forEach(({ column, field, reference_table }) => {
      const reference = version.gtfs_json[reference_table][values[field]]
      values[column] = reference
        ? reference[Object.keys(reference).find((key) => key.includes("id"))]
        : ""
    })
    select_fields.forEach(({ column, field, options }) => {
      const option = options.find((option) => option.value === values[field])
      values[column] = option ? option.label : ""
    })
    return values
  })

  return (
    <Box sx={{ height: "calc( 100vh - 102px )", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[5, 10, 15, 20, 25]}
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <CreateDialog
        version={version}
        set_version={set_version}
        set_version_changes={set_version_changes}
        fields={fields}
        table={table}
      />
      <EditDialog
        version={version}
        edit_row={edit_row}
        set_edit_row={set_edit_row}
        set_version={set_version}
        set_version_changes={set_version_changes}
        fields={fields}
        table={table}
      />
      <DeleteDialog
        delete_row={delete_row}
        set_delete_row={set_delete_row}
        version={version}
        set_version={set_version}
        set_version_changes={set_version_changes}
        fields={fields}
        table={table}
      />
    </Box>
  )
}
