import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import axios from "axios"
import Alert from "@mui/material/Alert"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import LockedButton from "../../../LockedButton/LockedButton"
import config from "../../../../config.json"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export default function ChangesTable(props) {
  const {
    version_changes,
    set_version_changes,
    set_initial_state,
    version,
    set_version,
    feed_pk,
    source,
    pk,
  } = props

  const classes = useStyles()
  const [alerts, set_alerts] = useState([])
  const [loading, set_loading] = useState(false)
  const [wip_name, set_wip_name] = useState("")

  const discard_changes = () => {
    set_initial_state(source, pk, set_version, set_version_changes)
    set_version_changes([])
  }

  const save_new_version = async () => {
    try {
      set_alerts([])
      set_loading(true)
      await axios.post(config.api_url + "/version", {
        feed_pk,
        gtfs_json: version.gtfs_json,
        gtfs_changes: version_changes,
      })
      set_alerts([
        {
          severity: "success",
          text: `New Version Saved.`,
        },
      ])
    } catch (error) {
      console.log(error)
      set_alerts(
        error.response.data.map((text) => {
          return {
            severity: "error",
            text,
          }
        })
      )
    } finally {
      set_loading(false)
    }
  }

  const save_wip = async () => {
    try {
      set_loading(true)
      await axios.post(config.api_url + "/work_in_progress", {
        feed_pk,
        gtfs_json: version.gtfs_json,
        gtfs_changes: version_changes,
        name: wip_name,
      })
      const new_alerts = []
      new_alerts.push({
        severity: "success",
        text: `Changes Saved For Later.`,
      })
      set_alerts(new_alerts)
      set_loading(false)
    } catch (error) {
      console.log(error)
      set_alerts(
        error.response.data.map((text) => {
          return {
            severity: "error",
            text,
          }
        })
      )
    }
  }

  const validate_feed = async () => {
    try {
      set_loading(true)
      const { gtfs_json } = version
      const { data } = await axios.post(config.api_url + "/validate", {
        gtfs_json,
      })
      let { feed_notices, warnings, errors } = data

      feed_notices = feed_notices.map((feed_notice) => {
        return { severity: "notice", text: feed_notice }
      })
      warnings = warnings.map((warning) => {
        return { severity: "warning", text: warning }
      })
      errors = errors.map((error) => {
        return { severity: "error", text: error }
      })
      set_alerts([...feed_notices, ...errors, ...warnings])
    } catch (error) {
      console.log(error)
    } finally {
      set_loading(false)
    }
  }

  return (
    <div className={"table_padding"}>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "calc( 100vh - 64px )" }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Update Type</b>
              </TableCell>
              <TableCell>
                <b>Action Type</b>
              </TableCell>
              <TableCell>
                <b>Old Values</b>
              </TableCell>
              <TableCell>
                <b>New Values</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {version_changes.map((change, index) => {
              const old_values_array = Object.keys(change.old_values)
                .map((key) => change.old_values[key])
                .filter((element) => typeof element !== "object")
              const new_values_array = Object.keys(change.new_values)
                .map((key) => change.new_values[key])
                .filter((element) => typeof element !== "object")
              return (
                <TableRow key={index}>
                  <TableCell>{change.gtfs_type}</TableCell>
                  <TableCell>{change.action_type}</TableCell>
                  <TableCell>
                    {old_values_array.length > 0
                      ? old_values_array.join(", ")
                      : "Not Applicable"}
                  </TableCell>
                  <TableCell>
                    {new_values_array.length > 0
                      ? new_values_array.join(", ")
                      : "Not Applicable"}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <div style={{ padding: "20px" }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                fullWidth
                onClick={discard_changes}
                color="primary"
              >
                Discard Changes
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="outlined"
                onClick={validate_feed}
                color="primary"
              >
                Validate
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="center" justify="center">
                <TextField
                  fullWidth
                  size="small"
                  label="Name"
                  value={wip_name}
                  onChange={(e) => {
                    set_wip_name(e.target.value)
                  }}
                  style={{ marginBottom: "8px" }}
                />

                <LockedButton
                  text="Save For Later"
                  fullWidth
                  onClick={() => {
                    if (wip_name) {
                      save_wip()
                    } else {
                      set_alerts([
                        { severity: "error", text: "No Name Entered" },
                      ])
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="center" justify="center">
                <LockedButton
                  text="Save new version"
                  fullWidth
                  onClick={() => {
                    save_new_version()
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              margin: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : null}
        {alerts.map((alert, i) => {
          return (
            <Alert key={i} severity={alert.severity}>
              {alert.text}
            </Alert>
          )
        })}
      </TableContainer>
    </div>
  )
}
