import React, { Fragment, useState, useEffect } from "react"
import {
  Route,
  Routes,
  Link,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom"
import MapView from "./MapView"
import axios from "axios"
import GenericTable from "./GenericTable"
import formatDate from "../EditGtfs/utils/formatDate"
import Search from "./Search"
import SearchIcon from "@mui/icons-material/Search"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn"
import TimerIcon from "@mui/icons-material/Timer"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import MapIcon from "@mui/icons-material/Map"
import ListItemIcon from "@mui/material/ListItemIcon"
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined"
import {
  ListItemButton,
  Box,
  CircularProgress,
  ListItemText,
  List,
  Divider,
} from "@mui/material"
const config = require("../../../config.json")

const drawerWidth = 220

export default function ViewGtfs(props) {
  const { source, pk, feed_pk } = useParams()
  const [gtfs_json, set_gtfs_json] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(config.api_url + `/${source}/${pk}`)
      .then((response) => {
        let version = response.data
        let { gtfs_json } = version
        let {
          shapes,
          trips,
          routes,
          calendar,
          calendar_dates,
          stop_times,
          stops,
          frequencies,
          agencies,
        } = gtfs_json

        //Add Coordinates from Shapes
        let coordinates = []
        Object.keys(shapes).forEach((shape_key) => {
          const shape = shapes[shape_key]
          Object.keys(shape.coordinates).forEach((coordinate_key) => {
            let coordinate = shape.coordinates[coordinate_key]
            coordinate.shape_id = shape.shape_id
            coordinates.push(coordinate)
          })
        })
        gtfs_json.coordinates = {}
        coordinates.forEach((coordinate, index) => {
          gtfs_json.coordinates[index] = coordinate
        })

        //Add route_id, service_id, shape_id to trips
        Object.keys(trips).forEach((trip_key) => {
          trips[trip_key].route_id = routes[trips[trip_key].route_key].route_id
          trips[trip_key].service_id =
            calendar[trips[trip_key].calendar_key].service_id
          trips[trip_key].shape_id = shapes[trips[trip_key].shape_key].shape_id
        })

        //Add service_id to calendar_dates
        Object.keys(calendar_dates).forEach((calendar_date_key) => {
          calendar_dates[calendar_date_key].service_id =
            calendar[calendar_dates[calendar_date_key].calendar_key].service_id
        })

        //Format Dates
        Object.keys(calendar_dates).forEach((calendar_date_key) => {
          calendar_dates[calendar_date_key].date = formatDate(
            calendar_dates[calendar_date_key].date
          )
        })
        Object.keys(calendar).forEach((calendar_key) => {
          calendar[calendar_key].start_date = formatDate(
            calendar[calendar_key].start_date
          )
          calendar[calendar_key].end_date = formatDate(
            calendar[calendar_key].end_date
          )
        })

        //Add trip_id, stop_id to stop_times
        Object.keys(stop_times).forEach((stop_time_key) => {
          stop_times[stop_time_key].trip_id =
            trips[stop_times[stop_time_key].trip_key].trip_id
          stop_times[stop_time_key].stop_id =
            stops[stop_times[stop_time_key].stop_key].stop_id
        })

        //Add trip_id to frequencies
        Object.keys(frequencies).forEach((frequency_key) => {
          frequencies[frequency_key].trip_id =
            trips[frequencies[frequency_key].trip_key].trip_id
        })

        //Add agency_id to routes
        Object.keys(routes).forEach((route_key) => {
          routes[route_key].agency_id =
            Object.keys(agencies).length === 1
              ? agencies[Object.keys(agencies)[0]].agency_id
              : agencies[routes[route_key].agency_key].agency_id
        })

        set_gtfs_json(gtfs_json)
      })
      .catch((error) => {
        console.log(error)
        navigate("/home")
      })
  }, [source, pk, navigate])

  const location = useLocation()
  const { pathname } = location
  const current_extension = "/" + pathname.split("/").pop()
  const base_path = pathname.split("/").slice(0, -1).join("/")

  const list_items = [
    { key: "search", url: "/search", text: "Search", icon: <SearchIcon /> },
    {
      key: "feed_info",
      url: "/feed_info",
      text: "Feed Info",
      icon: <InfoOutlinedIcon />,
    },
    {
      key: "agency",
      url: "/agency",
      text: "Agencies",
      icon: <InfoOutlinedIcon />,
    },
    {
      key: "routes",
      url: "/routes",
      text: "Routes",
      icon: <FormatListNumberedIcon />,
    },
    {
      key: "calendar",
      url: "/calendar",
      text: "Calendar",
      icon: <CalendarTodayIcon />,
    },
    {
      key: "calendar_dates",
      url: "/calendar_dates",
      text: "Calendar Dates",
      icon: <CalendarTodayIcon />,
    },
    {
      key: "stops",
      url: "/stops",
      text: "Stops",
      icon: <FormatListNumberedIcon />,
    },
    {
      key: "shapes",
      url: "/shapes",
      text: "Shapes",
      icon: <FormatListNumberedIcon />,
    },
    {
      key: "trips",
      url: "/trips",
      text: "Trips",
      icon: <FormatListNumberedIcon />,
    },
    {
      key: "stop_times",
      url: "/stop_times",
      text: "Stop Times",
      icon: <TimerIcon />,
    },
    {
      key: "frequencies",
      url: "/frequencies",
      text: "Frequencies",
      icon: <TimerIcon />,
    },
    {
      key: "fare_attributes",
      url: "/fare_attributes",
      text: "Fare Attributes",
      icon: <AccountBalanceWalletOutlinedIcon />,
    },
    {
      key: "fare_rules",
      url: "/fare_rules",
      text: "Fare Rules",
      icon: <AccountBalanceWalletOutlinedIcon />,
    },
    { key: "map", url: "/map", text: "Map", icon: <MapIcon /> },
  ]

  return (
    <Box sx={{ display: "flex", backgroundColor: "white" }}>
      <div
        style={{
          height: "calc( 100vh - 64px )",
          width: drawerWidth,
          borderRight: "1px lightgrey solid",
          overflow: "auto",
        }}
      >
        <Divider />
        <List style={{ paddingTop: "0px" }}>
          {list_items.map((list_item, index) => {
            return (
              <Fragment key={list_item.key}>
                <ListItemButton
                  title={list_item.url}
                  component={Link}
                  to={base_path + list_item.url}
                  style={
                    current_extension === list_item.url
                      ? {
                          backgroundColor: "lightgray",
                          border: "1px solid #555",
                        }
                      : { backgroundColor: null }
                  }
                >
                  <ListItemIcon>{list_item.icon}</ListItemIcon>
                  <ListItemText primary={list_item.text} />
                </ListItemButton>
                <Divider />
              </Fragment>
            )
          })}
          <ListItemButton component={Link} to={`/feed/${feed_pk}`}>
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </ListItemButton>
          <Divider />
        </List>
      </div>
      <div style={{ width: `calc( 100vw - ${drawerWidth}px )` }}>
        {gtfs_json === null ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Routes>
            <Route
              path={"search"}
              element={<Search gtfs_json={gtfs_json} {...props} />}
            />
            <Route
              path={"feed_info"}
              element={
                <GenericTable
                  data={gtfs_json.feed_info}
                  columns={[
                    {
                      headerName: "Publisher Name",
                      field: "feed_publisher_name",
                    },
                    {
                      headerName: "Publisher URL",
                      field: "feed_publisher_url",
                    },
                    { headerName: "Language", field: "feed_lang" },
                    { headerName: "Default Language", field: "default_lang" },
                    { headerName: "Start Date", field: "feed_start_date" },
                    { headerName: "End Date", field: "feed_end_date" },
                    {
                      headerName: "Contact Email",
                      field: "feed_contact_email",
                    },
                    { headerName: "Contact URL", field: "feed_contact_url" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"agency"}
              element={
                <GenericTable
                  data={gtfs_json.agencies}
                  columns={[
                    { headerName: "ID", field: "agency_id" },
                    { headerName: "Name", field: "agency_name" },
                    { headerName: "URL", field: "agency_url" },
                    { headerName: "Phone", field: "agency_phone" },
                    { headerName: "Timezone", field: "agency_timezone" },
                    { headerName: "Language", field: "agency_lang" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"stops"}
              element={
                <GenericTable
                  data={gtfs_json.stops}
                  columns={[
                    { headerName: "Stop Name", field: "stop_name" },
                    { headerName: "Stop Id", field: "stop_id" },
                    { headerName: "Stop Latitude", field: "stop_lat" },
                    { headerName: "Stop Longitude", field: "stop_lon" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"routes"}
              element={
                <GenericTable
                  data={gtfs_json.routes}
                  columns={[
                    { headerName: "Route Id", field: "route_id" },
                    { headerName: "Agency Id", field: "agency_id" },
                    {
                      headerName: "Route Short Name",
                      field: "route_short_name",
                    },
                    { headerName: "Route Long Name", field: "route_long_name" },
                    { headerName: "Route Type", field: "route_type" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"trips"}
              element={
                <GenericTable
                  data={gtfs_json.trips}
                  columns={[
                    { headerName: "Trip Id", field: "trip_id" },
                    { headerName: "Route Id", field: "route_id" },
                    { headerName: "Service Id", field: "service_id" },
                    { headerName: "Shape Id", field: "shape_id" },
                    { headerName: "Direction Id", field: "direction_id" },
                    { headerName: "Trip Headsign", field: "trip_headsign" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"stop_times"}
              element={
                <GenericTable
                  data={gtfs_json.stop_times}
                  columns={[
                    { headerName: "Trip Id", field: "trip_id" },
                    { headerName: "Stop Id", field: "stop_id" },
                    { headerName: "Stop Sequence", field: "stop_sequence" },
                    { headerName: "Arrival Time", field: "arrival_time" },
                    { headerName: "Departure Time", field: "departure_time" },
                    { headerName: "Timepoint", field: "time_point" },
                    { headerName: "Stop Headsign", field: "stop_headsign" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"calendar"}
              element={
                <GenericTable
                  data={gtfs_json.calendar}
                  columns={[
                    { headerName: "Service Id", field: "service_id" },
                    { headerName: "Monday", field: "monday" },
                    { headerName: "Tuesday", field: "tuesday" },
                    { headerName: "Wednesday", field: "wednesday" },
                    { headerName: "Thursday", field: "thursday" },
                    { headerName: "Friday", field: "friday" },
                    { headerName: "Saturday", field: "saturday" },
                    { headerName: "Sunday", field: "sunday" },
                    { headerName: "Start Date", field: "start_date" },
                    { headerName: "End Date", field: "end_date" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"calendar_dates"}
              element={
                <GenericTable
                  data={gtfs_json.calendar_dates}
                  columns={[
                    { headerName: "Service Id", field: "service_id" },
                    { headerName: "Exception Type", field: "exception_type" },
                    { headerName: "Date", field: "date" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"fare_attributes"}
              element={
                <GenericTable
                  data={gtfs_json.fare_attributes}
                  columns={[
                    { headerName: "Fare Id", field: "fare_id" },
                    { headerName: "Price", field: "price" },
                    { headerName: "Currency Type", field: "currency_type" },
                    { headerName: "Payment Method", field: "payment_method" },
                    { headerName: "Transfers", field: "transfers" },
                    {
                      headerName: "Transfer Duration",
                      field: "transfer_duration",
                    },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"fare_rules"}
              element={
                <GenericTable
                  data={gtfs_json.fare_rules}
                  columns={[
                    { headerName: "Fare Id", field: "fare_id" },
                    { headerName: "Route Id", field: "route_id" },
                    { headerName: "Origin Id", field: "origin_id" },
                    { headerName: "Destination Id", field: "destination_id" },
                    { headerName: "Contains Id", field: "contains_id" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"shapes"}
              element={
                <GenericTable
                  data={gtfs_json.coordinates}
                  columns={[
                    { headerName: "Shape Id", field: "shape_id" },
                    { headerName: "Longitude", field: "shape_pt_lon" },
                    { headerName: "Latitude", field: "shape_pt_lat" },
                    { headerName: "Sequence", field: "shape_pt_sequence" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"frequencies"}
              element={
                <GenericTable
                  data={gtfs_json.frequencies}
                  columns={[
                    { headerName: "Trip Id", field: "trip_id" },
                    { headerName: "Start Time", field: "start_time" },
                    { headerName: "End Time", field: "end_time" },
                    { headerName: "Headway Secs", field: "headway_secs" },
                    { headerName: "Exact Times", field: "exact_times" },
                  ]}
                  {...props}
                />
              }
            />
            <Route
              path={"map"}
              element={
                <MapView
                  stops={gtfs_json.stops}
                  shapes={gtfs_json.shapes}
                  trips={gtfs_json.trips}
                  stop_times={gtfs_json.stop_times}
                  {...props}
                />
              }
            />
          </Routes>
        )}
      </div>
    </Box>
  )
}
