import "./styles.css"

export default function Background({ children }) {
  return (
    <div className="circle_background">
      {/* <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
      <div style={{ position: "relative" }}> {children}</div>
    </div>
  )
}
