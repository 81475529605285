const Bubble = ({ children, shrink = false }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        margin: 10,
        padding: 10,
        width: shrink ? "max-content" : null,
      }}
    >
      {children}
    </div>
  )
}

export { Bubble }
