const language_options = [
  { value: "en", label: "English" },
  { value: "zh", label: "Chinese" },
  { value: "es", label: "Spanish" },
  { value: "bn", label: "Bangla" },
  { value: "hi", label: "Hindi" },
  { value: "pt", label: "Portuguese" },
  { value: "ru", label: "Russian" },
  { value: "ja", label: "Japanese" },
  { value: "de", label: "German" },
  { value: "jv", label: "Javanese" },
  { value: "ko", label: "Korean" },
  { value: "fr", label: "French" },
  { value: "vi", label: "Vietnamese" },
  { value: "te", label: "Telugu" },
  { value: "mr", label: "Marathi" },
  { value: "ta", label: "Tamil" },
  { value: "tr", label: "Turkish" },
  { value: "ur", label: "Urdu" },
]

const timezone_options = [
  { value: "UTC", label: "UTC" },
  { value: "UTC-12", label: "UTC-12" },
  { value: "UTC-11", label: "UTC-11" },
  { value: "UTC-10", label: "UTC-10" },
  { value: "UTC-9", label: "UTC-9" },
  { value: "UTC-8", label: "UTC-8" },
  { value: "UTC-7", label: "UTC-7" },
  { value: "UTC-6", label: "UTC-6" },
  { value: "UTC-5", label: "UTC-5" },
  { value: "UTC-4", label: "UTC-4" },
  { value: "UTC-3", label: "UTC-3" },
  { value: "UTC-2", label: "UTC-2" },
  { value: "UTC-1", label: "UTC-1" },
  { value: "UTC+1", label: "UTC+1" },
  { value: "UTC+2", label: "UTC+2" },
  { value: "UTC+3", label: "UTC+3" },
  { value: "UTC+4", label: "UTC+4" },
  { value: "UTC+5", label: "UTC+5" },
  { value: "UTC+6", label: "UTC+6" },
  { value: "UTC+7", label: "UTC+7" },
  { value: "UTC+8", label: "UTC+8" },
  { value: "UTC+9", label: "UTC+9" },
  { value: "UTC+10", label: "UTC+10" },
  { value: "UTC+11", label: "UTC+11" },
  { value: "UTC+12", label: "UTC+12" },
]

const service_options = [
  { value: "0", label: "Available" },
  { value: "1", label: "Unavailable" },
]

const service_exception_options = [
  { value: "1", label: "Added" },
  { value: "2", label: "Removed" },
]

const trip_direction_options = [
  { value: "0", label: "Outbound" },
  { value: "1", label: "Inbound" },
]

const route_types_options = [
  { value: "0", label: "Tram, Streetcar, Light rail" },
  { value: "1", label: "Subway, Metro" },
  { value: "2", label: "Rail" },
  { value: "3", label: "Bus" },
  { value: "4", label: "Ferry" },
  { value: "5", label: "Cable tram" },
  { value: "6", label: "Aerial lift, suspended cable car" },
  { value: "7", label: "Funicular" },
  { value: "11", label: "Trolleybus" },
  { value: "12", label: "Monorail" },
]

const timepoint_options = [
  { value: "0", label: "Approximate" },
  { value: "1", label: "Exact" },
]

const exact_time_options = [
  { value: "0", label: "Frequency" },
  { value: "1", label: "Schedule" },
]

const payment_method_options = [
  { value: "0", label: "On board" },
  { value: "1", label: "Before boarding" },
]

export {
  payment_method_options,
  language_options,
  timezone_options,
  service_options,
  service_exception_options,
  trip_direction_options,
  route_types_options,
  timepoint_options,
  exact_time_options,
}
