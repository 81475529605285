const service_id_html = (
  <span>
    <p>
      Uniquely identifies a set of dates when service is available for one or
      more routes. Each service_id value can appear at most once.
    </p>
  </span>
)

const monday_html = (
  <span>
    <p>
      Indicates whether the service operates on all Mondays in the date range
      specified by the start_date and end_date fields. Note that exceptions for
      particular dates may be listed in Calendar Dates. Valid options are:
    </p>
    <p>Available - Service is available for all Mondays in the date range.</p>
    <p>Unavailable - Service is not available for Mondays in the date range.</p>
  </span>
)

const tuesday_html = (
  <span>
    <p>
      Indicates whether the service operates on all Tuesdays in the date range
      specified by the start_date and end_date fields. Note that exceptions for
      particular dates may be listed in Calendar Dates. Valid options are:
    </p>
    <p>Available - Service is available for all Tuesdays in the date range.</p>
    <p>
      Unavailable - Service is not available for Tuesdays in the date range.
    </p>
  </span>
)

const wednesday_html = (
  <span>
    <p>
      Indicates whether the service operates on all Wednesdays in the date range
      specified by the start_date and end_date fields. Note that exceptions for
      particular dates may be listed in Calendar Dates. Valid options are:
    </p>
    <p>
      Available - Service is available for all Wednesdays in the date range.
    </p>
    <p>
      Unavailable - Service is not available for Wednesdays in the date range.
    </p>
  </span>
)

const thursday_html = (
  <span>
    <p>
      Indicates whether the service operates on all Thursdays in the date range
      specified by the start_date and end_date fields. Note that exceptions for
      particular dates may be listed in Calendar Dates. Valid options are:
    </p>
    <p>Available - Service is available for all Thursdays in the date range.</p>
    <p>
      Unavailable - Service is not available for Thursdays in the date range.
    </p>
  </span>
)

const friday_html = (
  <span>
    <p>
      Indicates whether the service operates on all Fridays in the date range
      specified by the start_date and end_date fields. Note that exceptions for
      particular dates may be listed in Calendar Dates. Valid options are:
    </p>
    <p>Available - Service is available for all Fridays in the date range.</p>
    <p>Unavailable - Service is not available for Fridays in the date range.</p>
  </span>
)

const saturday_html = (
  <span>
    <p>
      Indicates whether the service operates on all Saturdays in the date range
      specified by the start_date and end_date fields. Note that exceptions for
      particular dates may be listed in Calendar Dates. Valid options are:
    </p>
    <p>Available - Service is available for all Saturdays in the date range.</p>
    <p>
      Unavailable - Service is not available for Saturdays in the date range.
    </p>
  </span>
)

const sunday_html = (
  <span>
    <p>
      Indicates whether the service operates on all Sundays in the date range
      specified by the start_date and end_date fields. Note that exceptions for
      particular dates may be listed in Calendar Dates. Valid options are:
    </p>
    <p>Available - Service is available for all Sundays in the date range.</p>
    <p>Unavailable - Service is not available for Sundays in the date range.</p>
  </span>
)

const start_date_html = (
  <span>
    <p>Start service day for the service interval.</p>
  </span>
)

const end_date_html = (
  <span>
    <p>
      End service day for the service interval. This service day is included in
      the interval.
    </p>
  </span>
)

export {
  service_id_html,
  monday_html,
  tuesday_html,
  wednesday_html,
  thursday_html,
  friday_html,
  saturday_html,
  sunday_html,
  start_date_html,
  end_date_html,
}
