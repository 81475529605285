const origin_id_html = (
  <span>
    <p>Identifies an origin zone.</p>
  </span>
)

const destination_id_html = (
  <span>
    <p>Identifies a destination zone.</p>
  </span>
)

const constains_id_html = (
  <span>
    <p>
      Identifies the zones that a rider will enter while using a given fare class. Used in some systems to calculate
      correct fare class.
    </p>
  </span>
)

const fare_id_html = (
  <span>
    <p>Identifies a fare class.</p>
  </span>
)

const route_id_html = (
  <span>
    <p>Identifies a route associated with the fare class.</p>
  </span>
)

export { origin_id_html, destination_id_html, constains_id_html, fare_id_html, route_id_html }
