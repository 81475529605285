import config from "../../../config.json"

const determine_initial_view_state = (stops) => {
  let latitude, longitude
  if (Object.keys(stops).length !== 0) {
    let sum_lats = 0
    let sum_lons = 0
    Object.keys(stops).forEach((key) => {
      sum_lats += stops[key].stop_lat
      sum_lons += stops[key].stop_lon
    })
    latitude = sum_lats / Object.keys(stops).length
    longitude = sum_lons / Object.keys(stops).length
  } else {
    latitude = config.default_map_settings.lat
    longitude = config.default_map_settings.lng
  }
  return {
    longitude,
    latitude,
    zoom: config.default_map_settings.zoom,
  }
}

export { determine_initial_view_state }
